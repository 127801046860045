import { useLazyQuery } from "@apollo/client"
import { CloudDownloadOutlined } from "@mui/icons-material"
import { Backdrop, Button, CircularProgress, Grid, Typography, useTheme } from "@mui/material"
import moment from "moment"
import { useContext, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { PageHeading } from "../../../partials/layout/heading/page-heading"
import { CustomMap, IMarker } from "../../../partials/maps/custom-map"
import { HouseholdDischargesContext } from "../context/household-discharges-context"
import { HouseholdDischargeInfoWindow } from "./household-discharge-info-window"
import { EXPORT_HOUSEHOLD_TOUR_QUERY } from "../../../../api/graphql/queries/export-household-tour"
import { downloadXmlString } from "../../../../utils/browser"
import { toast } from "react-toastify"

export const HouseholdDischargeMap: React.FC = () => {
  const { householdDischarges, selectedTour } = useContext(HouseholdDischargesContext)
  const { t } = useTranslation()
  const theme = useTheme()

  const [exportHouseholdTour, { loading: exportLoading }] = useLazyQuery(EXPORT_HOUSEHOLD_TOUR_QUERY, {
    fetchPolicy: "no-cache",
  })

  const markers: IMarker[] = useMemo(() => {
    return (householdDischarges ?? [])
      .sort((h1, h2) => h1.sequenceNumber - h2.sequenceNumber)
      .map((discharge, index) => {
        const householdOfContainer = discharge.householdContainer?.household
        if (!householdOfContainer) return null

        return {
          id: Number(discharge.id),
          index: index,
          lat: householdOfContainer.latitude,
          lng: householdOfContainer.longitude,
          sequenceNumber: discharge.sequenceNumber,
        }
      })
      .filter((marker) => marker !== null) as IMarker[]
  }, [householdDischarges])

  const exportDischarges = async () => {
    if (!selectedTour) return
    const queryResult = await exportHouseholdTour({ variables: { tourId: Number(selectedTour.id) } })
    const xml: string | undefined = queryResult.data?.exportHouseholdTour

    if (!xml) {
      toast.error(t("errors.generic"))
      return
    }
    const date = moment(selectedTour.tourDate).format("DD_MM_YYYY")
    const plate = selectedTour.vehicleTypePlate
    const vehicleId = selectedTour.vehicleIdIdentSystem
    const fileName = `${date}_${plate}_${vehicleId}`
    downloadXmlString(xml, fileName)
  }

  if (!selectedTour) return

  return (
    <>
      <Backdrop open={exportLoading} sx={{ zIndex: theme.zIndex.modal + 1 }}>
        <CircularProgress color="primary" />
      </Backdrop>
      <Grid container direction="row" justifyContent="space-between">
        <Grid item>
          <PageHeading>{`${t("household_discharges.heading")} ${moment(selectedTour?.tourDate).format(t("date_format"))}`}</PageHeading>
        </Grid>
        <Grid item>
          <Button variant="outlined" startIcon={<CloudDownloadOutlined />} onClick={exportDischarges}>
            <Typography sx={{ textTransform: "none" }}>{t("household_discharges.export")}</Typography>
          </Button>
        </Grid>
      </Grid>
      <CustomMap
        height={"400px"}
        markers={markers}
        center={markers.length === 0 ? { id: -1, lat: 47.076, lng: 15.421 } : undefined}
        doRenderCustomInfoWindow
        renderCustomInfoWindow={(markerId) => <HouseholdDischargeInfoWindow key={markerId} dischargeId={markerId} />}
        placeSearch
      />
    </>
  )
}
