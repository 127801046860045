import React, { useContext, useState } from "react"
import {
  Checkbox,
  Chip,
  FormControlLabel,
  Grid,
  IconButton,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material"
import moment from "moment"
import { useTranslation } from "react-i18next"
import { ArrowOutward, Delete, WarningRounded } from "@mui/icons-material"
import { UserService } from "../../../../services/user-service"
import { UserRole } from "../../../../api/graphql/graphql-global-types"
import { HouseholdDischargesContext } from "../context/household-discharges-context"
import { PATH, replaceUriParameter } from "../../../../router/router"
import { useNavigate } from "react-router-dom"
import { HouseholdDischargeDeleteConfirmDialog } from "./household-discharge-delete-confirm-dialog"
import { toast } from "react-toastify"
import { HouseholdDischargesOfTourHousehold } from "../../../../api/graphql/queries/get-household-discharges-of-tour-paginated"

export const HouseholdDischargesTable: React.FC = () => {
  const { t } = useTranslation()
  const navigation = useNavigate()
  const entriesLoading = false
  const [selectedDischargeId, setDischargeId] = useState<number | undefined>()

  const {
    selectedTour,
    householdDischargesPaginated,
    tableProps,
    setTableProps,
    tableWarningFilter,
    setTableWarningFilter,
    refetchHouseholdDischargeData,
  } = useContext(HouseholdDischargesContext)

  const onDeleteCancel = () => {
    setDischargeId(undefined)
  }

  const onDeleteCompleted = () => {
    toast.success(t("household_discharges.delete_dialog.success"))
    setDischargeId(undefined)
    refetchHouseholdDischargeData()
  }

  const getHouseholdAddress = (household: HouseholdDischargesOfTourHousehold | undefined) => {
    if (!household) return "-"
    let addressParts: string[] = []

    if (household.place) addressParts.push(household.place)
    if (household.number) addressParts.push(household.number)
    const base1 = addressParts.length > 0 ? addressParts.join(" ") : undefined

    addressParts = []
    if (household.postal) addressParts.push(household.postal.toString())
    if (household.postal) addressParts.push(household.place)
    const base2 = addressParts.length > 0 ? addressParts.join(" ") : undefined

    return [base1, base2].filter(Boolean).join(", ")
  }

  if (!selectedTour) return

  return (
    <>
      <HouseholdDischargeDeleteConfirmDialog
        dischargeId={selectedDischargeId}
        onCompleted={onDeleteCompleted}
        onCancel={onDeleteCancel}
      />
      <Grid display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h6">{t("household_discharges.discharges")}</Typography>
        <FormControlLabel
          control={
            <Checkbox onChange={(event) => setTableWarningFilter(event.target.checked)} checked={tableWarningFilter} />
          }
          label={t("household_discharges.table.warning_filter")}
        />
      </Grid>
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>{t("household_discharges.table.time")}</TableCell>
              <TableCell>{t("household_discharges.table.address")}</TableCell>
              <TableCell>{t("household_discharges.table.chip_nr")}</TableCell>
              <TableCell>{t("household_discharges.table.warning")}</TableCell>
              <TableCell>{t("household_discharges.table.volume")}</TableCell>
              <TableCell>{t("household_discharges.table.weight")}</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {entriesLoading && (
              <TableRow>
                {Array.from({ length: 8 }).map((_, index) => {
                  return (
                    <TableCell key={index} align="center" sx={{ borderBottom: "none" }}>
                      <Skeleton height={50} />
                    </TableCell>
                  )
                })}
              </TableRow>
            )}
            {!entriesLoading && householdDischargesPaginated?.length === 0 && (
              <TableRow key={"household_discharges_no_data"}>
                <TableCell colSpan={8} align="center">
                  {t("table.no_data")}
                </TableCell>
              </TableRow>
            )}
            {!entriesLoading &&
              householdDischargesPaginated?.map((discharge, index) => (
                <TableRow key={index}>
                  <TableCell>{discharge.sequenceNumber}</TableCell>
                  <TableCell>{moment(discharge.date).format("HH:mm")}</TableCell>
                  <TableCell>
                    {!discharge.warning && getHouseholdAddress(discharge?.householdContainer?.household)}
                  </TableCell>
                  <TableCell>{discharge.rfidHex}</TableCell>
                  <TableCell>
                    {discharge.warning ? (
                      <Chip
                        label={t("household_discharges.table.unknown_rfid")}
                        icon={<WarningRounded fontSize="small" />}
                        sx={{
                          backgroundColor: "#fae884",
                          color: "#b58900",
                          px: 1,
                          "& .MuiChip-icon": {
                            color: "#b58900",
                          },
                        }}
                      />
                    ) : (
                      "-"
                    )}
                  </TableCell>
                  <TableCell>
                    {discharge.householdContainer?.type?.size && !discharge.warning
                      ? `${discharge.householdContainer?.type?.size} l`
                      : "-"}
                  </TableCell>
                  <TableCell>{`${discharge.weight} kg`}</TableCell>
                  <TableCell>
                    <Stack direction="row">
                      {[UserRole.SUPER_ADMIN, UserRole.COLLECTION_PARTNER].includes(UserService.getRole()) && (
                        <Tooltip title={t("household_discharges.table.delete")}>
                          <IconButton onClick={() => setDischargeId(Number(discharge.id))}>
                            <Delete />
                          </IconButton>
                        </Tooltip>
                      )}
                      <Tooltip title={t("household_discharges.table.container_details")}>
                        <span>
                          <IconButton
                            onClick={() =>
                              navigation(
                                replaceUriParameter(
                                  `${PATH.HOUSEHOLD_DETAILS.route}`,
                                  "householdContainerId",
                                  String(discharge.householdContainer?.id),
                                ),
                              )
                            }
                            disabled={!discharge.householdContainer?.id}
                          >
                            <ArrowOutward />
                          </IconButton>
                        </span>
                      </Tooltip>
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 100]}
          component="div"
          count={selectedTour.numDischarges ?? 0}
          rowsPerPage={tableProps.pageSize}
          page={tableProps.page}
          onPageChange={(_, page) => setTableProps({ ...tableProps, page })}
          onRowsPerPageChange={(event) => setTableProps({ page: 0, pageSize: parseInt(event.target.value, 10) })}
        />
      </TableContainer>
    </>
  )
}
