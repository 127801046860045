import { Grid, Typography } from "@mui/material"
import React from "react"

interface Props {
  label: string
  value: string
}

export const InfoWindowText: React.FC<Props> = (props) => {
  const { label, value } = props

  return (
    <Grid key={`${label}-${value}`} container direction="row">
      <Grid item xs={5}>
        <Typography fontWeight={700}>{label}:</Typography>
      </Grid>
      <Grid item xs={7}>
        <Typography>{value}</Typography>
      </Grid>
    </Grid>
  )
}
