import { Box, CircularProgress, Divider, Stack } from "@mui/material"
import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { InfoWindowText } from "../../../partials/info-window/info-window-text"
import { useQuery } from "@apollo/client"
import {
  GET_HOUSEHOLD_DISCHARGE_QUERY,
  HouseholdDischarge,
  HouseholdDischargeResult,
  HouseholdDischargeVariables,
} from "../../../../api/graphql/queries/get-household-discharge"
import { toast } from "react-toastify"

interface Props {
  dischargeId: number
}

export const HouseholdDischargeInfoWindow: React.FC<Props> = (props) => {
  const { dischargeId } = props
  const { t } = useTranslation()

  const onLoadError = () => {
    toast.error(t("errors.generic"))
  }

  const { data: householdDischargeData, loading } = useQuery<HouseholdDischargeResult, HouseholdDischargeVariables>(
    GET_HOUSEHOLD_DISCHARGE_QUERY,
    {
      variables: { dischargeId },
      onError: onLoadError,
    },
  )

  const householdDischarge: HouseholdDischarge | undefined = useMemo(
    () => householdDischargeData?.getHouseholdDischarge,
    [householdDischargeData],
  )
  const householdContainer = useMemo(() => householdDischarge?.householdContainer, [householdDischarge])
  const household = useMemo(() => householdContainer?.household, [householdContainer])

  return (
    <Box sx={{ minWidth: 300 }}>
      {loading && (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      )}
      {!loading && householdDischarge && (
        <Stack direction="column">
          {household && (
            <>
              <InfoWindowText label={t("household_discharges.info_window.household.household")} value={""} />
              <InfoWindowText label={t("household_discharges.info_window.household.id")} value={household.id} />
              <InfoWindowText
                label={t("household_discharges.info_window.household.street")}
                value={`${household.street} ${household.number}`}
              />
              <InfoWindowText
                label={t("household_discharges.info_window.household.place")}
                value={`${household.postal} ${household.place}`}
              />
            </>
          )}
          {householdContainer && (
            <>
              <Box pt={1} pb={1}>
                <Divider />
              </Box>
              <InfoWindowText label={t("household_discharges.info_window.household_container.container")} value={""} />
              <InfoWindowText
                label={t("household_discharges.info_window.household_container.id")}
                value={householdContainer.id}
              />
              <InfoWindowText
                label={t("household_discharges.info_window.household_container.rfid")}
                value={householdContainer.rfid ?? "-"}
              />
              <InfoWindowText
                label={t("household_discharges.info_window.household_container.comment")}
                value={householdContainer.comment ?? "-"}
              />
              <InfoWindowText
                label={t("household_discharges.info_window.household_container.material")}
                value={householdContainer.material?.name ?? "-"}
              />
            </>
          )}
          {householdDischarge && (
            <>
              <Box pt={1} pb={1}>
                <Divider />
              </Box>
              <InfoWindowText label={t("household_discharges.info_window.household_discharge.discharge")} value={""} />
              <InfoWindowText
                label={t("household_discharges.info_window.household_discharge.id")}
                value={householdDischarge.id}
              />
              <InfoWindowText
                label={t("household_discharges.info_window.household_discharge.tour_id")}
                value={householdDischarge.householdTourId ?? "-"}
              />
              <InfoWindowText
                label={t("household_discharges.info_window.household_discharge.sequence")}
                value={householdDischarge.sequenceNumber.toString()}
              />
              <InfoWindowText
                label={t("household_discharges.info_window.household_discharge.rfid_hex")}
                value={householdDischarge.rfidHex ?? "-"}
              />
              <InfoWindowText
                label={t("household_discharges.info_window.household_discharge.rfid_dec")}
                value={householdDischarge.rfidDec ?? "-"}
              />
              <InfoWindowText
                label={t("household_discharges.info_window.household_discharge.weight")}
                value={householdDischarge.weight ? householdDischarge.weight.toString() : "-"}
              />
            </>
          )}
        </Stack>
      )}
    </Box>
  )
}
