import React, { useContext, useMemo } from "react"
import { CircularProgress, Divider, Grid } from "@mui/material"
import { useNavigate, useParams } from "react-router-dom"
import { HouseholdContainerHeader } from "./partials/household-container-header"
import { HouseholdContainerDetails } from "./partials/household-container-details"
import { HouseholdContainerHistory } from "./partials/household-container-history"
import { HouseholdContainerContext, HouseholdContainerContextProvider } from "./context/household-container-context"
import { toast } from "react-toastify"
import { PATH } from "../../../router/router"
import { useTranslation } from "react-i18next"

interface IHouseholdContainerPageProps {}

export const HouseholdContainerPage: React.FC<IHouseholdContainerPageProps> = (_props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const params = useParams<{ householdContainerId: string }>()
  const containerId = useMemo(() => params.householdContainerId, [params.householdContainerId])
  const { containerLoading, containerError } = useContext(HouseholdContainerContext)

  if (containerLoading)
    return (
      <Grid container justifyContent="center" alignItems="center">
        <CircularProgress />
      </Grid>
    )

  if (containerError) {
    toast.error(t("household_container.container_error"))
    navigate(PATH.HOUSEHOLD_CONTAINER_OVERVIEW.route)
  }

  if (!containerId) return null

  return (
    <HouseholdContainerContextProvider containerId={containerId}>
      <Grid container direction="column" spacing={2} sx={{ pl: 2 }}>
        <Grid item>
          <HouseholdContainerHeader />
        </Grid>
        <Divider />
        <Grid item>
          <HouseholdContainerDetails />
        </Grid>
        <Grid item>
          <HouseholdContainerHistory />
        </Grid>
      </Grid>
    </HouseholdContainerContextProvider>
  )
}
