import React, { FunctionComponent } from "react"
import { SxProps, Theme } from "@mui/material"
import { TimePicker as MuiTimePicker } from "@mui/x-date-pickers"
import moment from "moment"
import { useTranslation } from "react-i18next"

const TIME_FORMAT = "HH:mm"
const pickerWidth = 150

interface ITimePickerProps {
  time: string // HH:mm
  onChange: (date: moment.Moment | null) => void
  sx?: SxProps<Theme>
  disabled?: boolean
  label?: string
  required?: boolean
  error?: string
}

export const TimePicker: FunctionComponent<ITimePickerProps> = (props) => {
  const { t } = useTranslation()
  const { time, onChange, sx, disabled, label, required, error } = props

  return (
    <MuiTimePicker
      slotProps={{
        textField: {
          fullWidth: true,
          required: required,
          helperText: error ? t("validation.time.notpossible") : undefined,
          error: !!error,
          variant: "standard",
        },
        desktopPaper: {
          sx: {
            width: pickerWidth,
          },
        },
        layout: {
          sx: {
            ".MuiMultiSectionDigitalClock-root": {
              ".MuiList-root": {
                width: "100%",
              },
            },
          },
        },
      }}
      ampm={false}
      sx={sx}
      format={TIME_FORMAT}
      disabled={disabled}
      label={label}
      value={moment(time, TIME_FORMAT)}
      onChange={onChange}
    />
  )
}
