import React, { FunctionComponent, Fragment, useState, useContext, useMemo } from "react"
import { Table, TableHead, TableBody, TableCell, TableRow, Backdrop, CircularProgress, useTheme } from "@mui/material"
import { useTranslation } from "react-i18next"
import lodash from "lodash"
import {
  StopInfo,
  StopInfoWeighing,
  StopFilllevelMaterial,
} from "../../../../api/graphql/queries/get-tour-stats-with-id"
import { CustomLightBox } from "../lightbox/custom-lightbox"
import { Image, ImageType } from "../../../../api/graphql/queries/get-initial-images-with-collection-point-id"
import {
  IImage,
  getImagesFromCollectionPointImages,
  getImageTypesFromCollectionPointImages,
  getImagesFromWeighings,
} from "../../../../utils/lightbox"
import { useQuery } from "@apollo/client"
import {
  GetDeparturePointsResult,
  GetDeparturePointsVariables,
  GET_DEPARTURE_POINTS,
} from "../../../../api/graphql/queries/get-departure-points"
import { StopsTableRow } from "./stops-table-row"
import { EditableStopsTableRow } from "./editable-stops-table-row"
import { ExportReason, StopStatus } from "../../../../api/graphql/graphql-global-types"
import { FIXED_UNLOAD_INTERVAL_ON_DEMAND } from "../../../../utils/constants"
import { UserContext } from "../../../../context/user-context"
import { useTourOverview } from "../../../pages/tour-overview/context/tour-overview-context"

interface IStopsTableProps {
  tourId?: number
  stopInfos: StopInfo[]
  onRowClicked?: (id: number) => void
  selectedStopId?: number
  isInEditMode: boolean
  onStopChanged?: (stop: StopInfo) => void
  onClickNewCollectionPoint?: (stopId: number) => void
  onClickDeleteCollectionPoint?: (stopId: number) => void
}

export const StopsTable: FunctionComponent<IStopsTableProps> = (props) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const {
    tourId,
    stopInfos,
    onRowClicked,
    selectedStopId,
    isInEditMode,
    onStopChanged,
    onClickNewCollectionPoint,
    onClickDeleteCollectionPoint,
  } = props
  const [selectedStopForImages, setSelectedStopForImages] = useState<number>()
  const { user } = useContext(UserContext)
  const { tourStatsData } = useTourOverview()

  const showReasonColumn = useMemo(
    () =>
      stopInfos.some(
        (stopInfo) =>
          [ExportReason.INTERVAL_DUE, ExportReason.EMPTYING_REQUESTED].find((reason) => reason === stopInfo.reason) ||
          stopInfo.fixed_unload_interval === FIXED_UNLOAD_INTERVAL_ON_DEMAND,
      ),
    [stopInfos],
  )

  const showHintColumn = useMemo(
    () =>
      stopInfos.some(
        (stopInfo) =>
          stopInfo.status === StopStatus.CANCELED ||
          (!tourStatsData?.getTourStatsWithId?.tour?.manuallyCreated &&
            stopInfo.reason === ExportReason.MANUALLY_INSERTED_BY_DRIVER),
      ),
    [stopInfos, tourStatsData],
  )

  const materials = lodash
    .uniqBy(
      stopInfos.reduce(
        (materialIds, stopInfo) => materialIds.concat(stopInfo.stop_filllevels.map((filllevel) => filllevel.material)),
        [] as StopFilllevelMaterial[],
      ),
      "id",
    )
    .sort((a, b) => (a.name < b.name ? -1 : 1))

  const getImagesForSelectedStop = (index: number): IImage[] => {
    let images: IImage[] = []
    if (!lodash.isEmpty(stopInfos[index].collection_point_images)) {
      images = images.concat(getImagesFromCollectionPointImages(stopInfos[index].collection_point_images as Image[]))
    }

    if (!lodash.isEmpty(stopInfos[index].weighings)) {
      images = images.concat(getImagesFromWeighings(stopInfos[index].weighings as StopInfoWeighing[]))
    }
    return images
  }

  const getImageTypesForSelectedStop = (index: number): ImageType[] => {
    let imageTypes: ImageType[] = []
    if (!lodash.isEmpty(stopInfos[index].collection_point_images)) {
      imageTypes = imageTypes.concat(
        getImageTypesFromCollectionPointImages(stopInfos[index].collection_point_images as Image[]),
      )
    }

    if (!lodash.isEmpty(stopInfos[index].weighings)) {
      imageTypes.push({
        id: "0",
        name: "weighing",
      } as ImageType)
    }

    return imageTypes
  }

  const { data: departurePointsData, loading: departurePointsLoading } = useQuery<
    GetDeparturePointsResult,
    GetDeparturePointsVariables
  >(GET_DEPARTURE_POINTS, {
    variables: {
      collectionPartnerId: String(user?.collection_partner?.id),
    },
    skip: !isInEditMode || user?.collection_partner?.id == null,
  })

  return (
    <Fragment>
      <Backdrop open={departurePointsLoading} sx={{ zIndex: theme.zIndex.modal + 1 }}>
        <CircularProgress color="primary" />
      </Backdrop>
      {/* Image-LightBox */}
      {selectedStopForImages !== undefined && (
        <CustomLightBox
          images={getImagesForSelectedStop(selectedStopForImages)}
          imageTypes={getImageTypesForSelectedStop(selectedStopForImages)}
          onClose={() => setSelectedStopForImages(undefined)}
        />
      )}
      <Table>
        <TableHead>
          <TableRow>
            <TableCell rowSpan={2} align="center">
              {t("tour_overview.table.mark")}
            </TableCell>
            <TableCell rowSpan={2} align="center">
              {t("tour_overview.table.type")}
            </TableCell>
            <TableCell rowSpan={2} align="center">
              {t("tour_overview.table.name")}
            </TableCell>
            <TableCell rowSpan={2} align="center">
              {t("tour_overview.table.address")}
            </TableCell>
            {showHintColumn && (
              <TableCell rowSpan={2} align="center">
                {t("tour_overview.table.hint")}
              </TableCell>
            )}
            {showReasonColumn && !isInEditMode && (
              <TableCell rowSpan={2} align="center">
                {t("tour_overview.reason")}
              </TableCell>
            )}
            {!lodash.isEmpty(materials) && (
              <TableCell colSpan={materials.length} align="center">
                {t("tour_overview.table.fill_level")}
              </TableCell>
            )}
            <TableCell rowSpan={2}></TableCell>
          </TableRow>
          {!lodash.isEmpty(materials) && (
            <TableRow>
              {materials.map((material) => (
                <TableCell sx={{ width: 100 }} key={material.id} align="center">
                  {material.name}
                </TableCell>
              ))}
            </TableRow>
          )}
        </TableHead>

        <TableBody>
          {stopInfos.map((stopInfo, idx) =>
            isInEditMode ? (
              !departurePointsLoading && (
                <EditableStopsTableRow
                  key={`${stopInfo.id}_${stopInfo.sequence_number}_${idx}`}
                  materials={materials}
                  departurePoints={departurePointsData?.getDeparturePoints || []}
                  stopInfo={stopInfo}
                  onStopChanged={onStopChanged}
                  isNewCollectionPointAllowed={idx !== stopInfos.length - 1}
                  onClickNewCollectionPoint={onClickNewCollectionPoint}
                  onClickDeleteCollectionPoint={onClickDeleteCollectionPoint}
                  showHintColumn={showHintColumn}
                />
              )
            ) : (
              <StopsTableRow
                key={`${stopInfo.id}_${stopInfo.sequence_number}_${idx}`}
                index={idx}
                materials={materials}
                onImageIconClicked={setSelectedStopForImages}
                onRowClicked={onRowClicked}
                selectedStopId={selectedStopId}
                tourId={tourId}
                stopInfo={stopInfo}
                showReasonColumn={showReasonColumn}
                showHintColumn={showHintColumn}
                sequenceNumber={idx + 1}
              />
            ),
          )}
        </TableBody>
      </Table>
    </Fragment>
  )
}
