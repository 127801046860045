import { gql } from "@apollo/client"
import {
  tourGenerations,
  tourGenerations_tourGenerations_entries,
  tourGenerationsVariables,
} from "./types/tourGenerations"

export const TOUR_GENERATIONS_QUERY = gql`
  query tourGenerations($page: Int!, $pageSize: Int!, $districtId: ID) {
    tourGenerations(page: $page, pageSize: $pageSize, districtId: $districtId) {
      totalPages
      totalEntries
      page
      pageSize
      entries {
        id
        district {
          id
          name
        }
        driving_days {
          week
          day
          vehicle {
            licence_plate
          }
        }
        start_date
        week_count
        message
        threshold
        is_connected_to_tour
        is_generation_aborted
        inserted_at
        hasParent
        hasChild
        hasPreview
        materials {
          id
          name
        }
        threshold_min
        version
        max_termination_time
        schedule_date
        reference_date
        towns {
          id
          name
        }
        without_containers
        collection_group_id
      }
    }
  }
`

export type TourGenerationsResult = tourGenerations
export type TourGenerationsVariables = tourGenerationsVariables
export type TourGeneration = tourGenerations_tourGenerations_entries
