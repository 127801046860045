import { gql } from "@apollo/client"
import {
  getHouseholdDischarge,
  getHouseholdDischarge_getHouseholdDischarge,
  getHouseholdDischargeVariables,
} from "./types/getHouseholdDischarge"

export const GET_HOUSEHOLD_DISCHARGE_QUERY = gql`
  query getHouseholdDischarge($dischargeId: Int!) {
    getHouseholdDischarge(discharge_id: $dischargeId) {
      id
      rfidDec
      rfidHex
      sequenceNumber
      weight
      householdContainerId
      householdTourId
      date
      householdContainer {
        id
        material {
          id
          name
          translation_key
        }
        comment
        rfid
        type {
          id
          name
        }
        household {
          id
          place
          postal
          street
          number
          place
        }
      }
    }
  }
`

export type HouseholdDischargeResult = getHouseholdDischarge
export type HouseholdDischargeVariables = getHouseholdDischargeVariables
export type HouseholdDischarge = getHouseholdDischarge_getHouseholdDischarge
