import { Backdrop, Box, Button, Card, CircularProgress, Stack, Typography, useTheme } from "@mui/material"
import React, { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useBlocker, useNavigate } from "react-router-dom"
import { PATH } from "../../../../router/router"
import { UnsavedDataDialog } from "../../../partials/dialogs/unsaved-data-dialog"
import { CollectionGroupWizardStep, useCollectionGroupWizardContext } from "../context/collection-group-wizard-context"
import { CollectionGroupWizardStepIndicator } from "./collection-group-wizard-step-indicator"

interface CollectionGroupWizardProps {
  groupId?: number
}

export const CollectionGroupWizard: React.FC<CollectionGroupWizardProps> = (props: CollectionGroupWizardProps) => {
  const { groupId } = props
  const { t } = useTranslation()
  const theme = useTheme()
  const { CurrentComponent, loading, currentStep, setCurrentStep, isStepValid, formValues, upsertCollectionGroup, mode } =
    useCollectionGroupWizardContext()
  const navigate = useNavigate()
  const [hasUnsavedData, setHasUnsavedData] = useState(false)
  const [hasUnsavedDataDialogOpen, setHasUnsavedDataDialogOpen] = useState(false)

  const shouldBlockNavigation = () => {
    if (hasUnsavedData) {
      setHasUnsavedDataDialogOpen(true)
      return true
    }
    return false
  }
  const blocker = useBlocker(shouldBlockNavigation)

  useEffect(() => {
    const someValues =
      formValues &&
      (formValues.collectionPointIds ||
        formValues.collectionPointIds ||
        formValues.label ||
        formValues.materialIds ||
        formValues.districtId ||
        formValues.selectedTown)

    setHasUnsavedData(!!someValues)
  }, [formValues])

  const onBack = useCallback(() => {
    if (currentStep === CollectionGroupWizardStep.General) {
      navigate(PATH.COLLECTION_GROUP_OVERVIEW.route)
      return
    }
    setCurrentStep(CollectionGroupWizardStep.General)
  }, [currentStep, navigate, setCurrentStep])

  const onContinue = useCallback(async () => {
    if (currentStep === CollectionGroupWizardStep.General) {
      setCurrentStep(CollectionGroupWizardStep.CollectionPoints)
      return
    }

    if (
      !formValues ||
      !formValues.districtId ||
      !formValues.label ||
      !formValues.materialIds ||
      !formValues.collectionPointIds
    ) {
      return
    }
    setHasUnsavedData(false)

    await upsertCollectionGroup({
      district_id: formValues?.districtId,
      label: formValues?.label,
      material_ids: formValues?.materialIds,
      collection_point_ids: formValues?.collectionPointIds,
    }, mode === "edit" ? groupId : undefined)
    navigate(PATH.COLLECTION_GROUP_OVERVIEW.route)
  }, [currentStep, setCurrentStep, navigate, formValues, upsertCollectionGroup, groupId, mode])

  return (
    <Stack direction="column">
      <UnsavedDataDialog
        open={hasUnsavedDataDialogOpen}
        onCancel={() => {
          setHasUnsavedDataDialogOpen(false)
        }}
        onOk={() => {
          blocker?.proceed?.()
        }}
      />
      <Backdrop open={loading} sx={{ zIndex: theme.zIndex.modal + 1 }}>
        <CircularProgress color="primary" />
      </Backdrop>

      <CollectionGroupWizardStepIndicator currentStep={currentStep} />

      <Box id="collection-groups-header-box" mx={2} mt={2}>
        <Typography variant="h4" color={"primary.main"}>
          {currentStep === CollectionGroupWizardStep.General
            ? t("collection_groups.mutate_page.header")
            : `${t("collection_groups.mutate_page.collection_points")} (${formValues?.collectionPointIds?.length ?? 0})`}
        </Typography>
      </Box>
      <Card sx={{ p: 2, m: 2, borderRadius: 4 }}>
        <CurrentComponent />
        <Stack direction="row" justifyContent="flex-end" spacing={2} pt={2}>
          <Button
            variant="outlined"
            onClick={onBack}
            sx={{
              borderRadius: 2,
              textTransform: "none",
              border: "none",
              "&:hover": { backgroundColor: "#F2F2F2", border: "none" },
            }}
          >
            <Typography color="black">
              {t(currentStep === CollectionGroupWizardStep.General ? "cancel" : "table.previous")}
            </Typography>
          </Button>
          <Button
            variant="contained"
            onClick={onContinue}
            disabled={!isStepValid(currentStep)}
            sx={{
              borderRadius: 2,
              textTransform: "none",
              backgroundColor: "#2F90F9",
              "&:hover": { backgroundColor: "#2F90F9" },
              "&.Mui-disabled": { backgroundColor: "#B3D0F9" },
            }}
          >
            <Typography color="white">
              {t(currentStep === CollectionGroupWizardStep.General ? "continue" : "collection_groups.mutate_page.complete")}
            </Typography>
          </Button>
        </Stack>
      </Card>
    </Stack>
  )
}
