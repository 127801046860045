import { useMutation } from "@apollo/client"
import React, { useCallback } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { DELETE_TOUR_DISCHARGE_MUTATION } from "../../../../api/graphql/mutations/delete-tour-discharge"
import { ConfirmDialog } from "../../../partials/layout/dialog/confirm-dialog"

interface IHouseholdDischargeDeleteConfirmDialogProps {
  dischargeId: number | undefined
  onCancel: VoidFunction
  onCompleted: VoidFunction
}

export const HouseholdDischargeDeleteConfirmDialog: React.FC<IHouseholdDischargeDeleteConfirmDialogProps> = (props) => {
  const { t } = useTranslation()
  const { onCancel, onCompleted, dischargeId } = props

  const [deleteMutation, { loading: isLoading }] = useMutation(DELETE_TOUR_DISCHARGE_MUTATION, {
    onError: () => toast.error(t("errors.generic")),
    onCompleted,
  })

  const onConfirm = useCallback(() => {
    if (!dischargeId) return
    deleteMutation({ variables: { dischargeId, updateSequence: true } })
  }, [deleteMutation, dischargeId])

  return (
    <ConfirmDialog
      open={!!dischargeId}
      onClose={onCancel}
      onConfirm={onConfirm}
      loading={isLoading}
      confirmText={t("household_discharges.delete_dialog.delete")}
      heading={t("household_discharges.delete_dialog.title")}
      text={t("household_discharges.delete_dialog.text")}
      negativeText={t("household_discharges.delete_dialog.cancel")}
    />
  )
}
