import { gql } from "@apollo/client"
import { GetCollectionGroupWithID, GetCollectionGroupWithID_getCollectionGroupWithID, GetCollectionGroupWithIDVariables } from "./types/GetCollectionGroupWithID"

export const GETCOLLECTIONGROUPWITHID_QUERY = gql`
  query GetCollectionGroupWithID($getCollectionGroupWithIdId: Int!) {
    getCollectionGroupWithID(id: $getCollectionGroupWithIdId) {
      error
      message
      id
      label
      district_id
      district {
        id
      }
      materials {
        id
      }
      collection_points {
        id
      }
    }
  }
`

export type CollectionGroupWithIDResult = GetCollectionGroupWithID
export type CollectionGroupWithIDVariables = GetCollectionGroupWithIDVariables
export type CollectionGroupWithID = GetCollectionGroupWithID_getCollectionGroupWithID