import { gql } from "@apollo/client"
import { rescheduleTour, rescheduleTourVariables } from "./types/rescheduleTour"

export const RESCHEDULE_TOUR_MUTATION = gql`
  mutation rescheduleTour($tourId: ID!, $newDate: DateTime!) {
    rescheduleTour(tourId: $tourId, newDate: $newDate)
  }
`

export type RescheduleTourResult = rescheduleTour
export type RescheduleTourVariables = rescheduleTourVariables
