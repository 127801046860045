import React, { FunctionComponent, useContext } from "react"
import { Card, Grid, useTheme } from "@mui/material"
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined"
import { Text } from "../../../partials/wrapper/text"
import moment from "moment"
import { useTranslation } from "react-i18next"
import { Badge } from "../../user-management/partials/badge"
import { hexToRgb } from "../../../../utils/color"
import { HouseholdTour } from "../../../../api/graphql/queries/get-household-tours"
import { HouseholdDischargesContext } from "../context/household-discharges-context"

interface IHouseholdTourItemProps {
  num: number
  item: HouseholdTour
}

export const HouseholdTourItem: FunctionComponent<IHouseholdTourItemProps> = (props) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const { item } = props

  const { selectedTour, setSelectedTour } = useContext(HouseholdDischargesContext)

  const onClick = () => {
    setSelectedTour(item)
  }

  const isSelected = () => {
    if (!selectedTour) return false
    return selectedTour.id === props.item.id
  }

  return (
    <Card
      sx={{
        m: 1,
        p: 1,
        "&:hover": {
          cursor: "pointer",
        },
        boxShadow: isSelected() ? `0 0 8px 0 rgba(${[...hexToRgb(theme.palette.primary.main)]}, 0.7)` : undefined,
      }}
      onClick={onClick}
    >
      <Grid container direction="column" spacing={0.5} sx={{ px: 1 }}>
        <Grid item container direction="row" justifyContent="space-between">
          <Grid item>
            <Grid item container alignItems="center" spacing={1} justifyContent="flex-start">
              <Grid item>
                <LocalShippingOutlinedIcon />
              </Grid>
              <Grid item xs>
                <Grid container justifyContent="space-between">
                  <Text fontSize={15} bold>
                    {item.vehicleTypePlate}
                  </Text>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            {item.numDischarges && (
              <Text fontSize={15}>
                {t("household_discharges.number_of_containers", {
                  count: item.numDischarges,
                })}
              </Text>
            )}
          </Grid>
        </Grid>
        <Grid item container direction="row" justifyContent="space-between">
          <Grid item>
            <Text fontSize={15}>{moment(item.tourDate).format(t("date_format"))}</Text>
          </Grid>
          <Grid item>
              <Text fontSize={15} color={item.dischargeWarningsCount > 0 ? "#b58900" : "#4BB543"}>
                {t("household_discharges.number_of_warnings", {
                  count: item.dischargeWarningsCount ?? 0,
                })}
              </Text>
          </Grid>
        </Grid>
        <Grid item container direction="row" alignItems="center" spacing={0.5} justifyContent="flex-start">
          {item.materials?.map((material) => (
            <Grid item key={material.id}>
              <Badge color="primary" fontSize={12}>
                {material.name}
              </Badge>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Card>
  )
}
