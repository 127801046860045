import { gql } from "@apollo/client"
import {
  getDistrictsWithTowns_getDistricts_towns,
  getDistrictsWithTowns_getDistricts,
  getDistrictsWithTowns,
  getDistrictsWithTowns_getDistricts_collection_groups,
} from "./types/getDistrictsWithTowns"

export const GET_DISTRICTS_WITH_TOWNS_AND_GROUPS_QUERY = gql`
  query getDistrictsWithTowns {
    getDistricts {
      id
      name
      allow_tourgeneration_town_multiselect
      allow_tourgeneration_group_select
      disposalTrailerLocationLimit
      allowMultipleWeeks
      towns {
        id
        name
      }
      collection_groups {
        id
        label
        district_id
        collection_points {
          id
        }
        materials {
          id
          name
        }
      }
    }
  }
`

export type DistrictsWithTownsResult = getDistrictsWithTowns
export type DistrictWithTowns = getDistrictsWithTowns_getDistricts
export type DistrictTown = getDistrictsWithTowns_getDistricts_towns
export type DistrictCollectionGroup = getDistrictsWithTowns_getDistricts_collection_groups
