import { Box, Step, StepLabel, Stepper, Typography } from "@mui/material"
import React from "react"
import { useTranslation } from "react-i18next"
import { CollectionGroupWizardStep } from "../context/collection-group-wizard-context"

interface Props {
  currentStep: CollectionGroupWizardStep
}

export const CollectionGroupWizardStepIndicator: React.FC<Props> = (props) => {
  const { currentStep } = props
  const { t } = useTranslation()

  return (
    <Box
      id="collection-groups-wizard-indicator-box"
      sx={{ height: 100, backgroundColor: "#FFFFFF", display: "flex", justifyContent: "center" }}
    >
      <Stepper sx={{ minWidth: 300, maxWidth: 600, width: "100%" }}>
        <Step
          active={currentStep === CollectionGroupWizardStep.General}
          completed={currentStep === CollectionGroupWizardStep.CollectionPoints}
        >
          <StepLabel>
            <Typography fontWeight={700}>{t("collection_groups.mutate_page.header")}</Typography>
          </StepLabel>
        </Step>
        <Step
          active={currentStep === CollectionGroupWizardStep.CollectionPoints}
        >
          <StepLabel>
            <Typography fontWeight={700}>{t("collection_groups.mutate_page.collection_points")}</Typography>
          </StepLabel>
        </Step>
      </Stepper>
    </Box>
  )
}
