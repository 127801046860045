import React from "react"
import { useTranslation } from "react-i18next"
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from "@mui/material"

interface Props {
  open: boolean
  onCancel: VoidFunction
  onOk: VoidFunction
}

export const UnsavedDataDialog: React.FC<Props> = (props) => {
  const { open, onCancel, onOk } = props
  const { t } = useTranslation()

  return (
    <Dialog open={open} maxWidth="xs" disableEscapeKeyDown>
      <DialogTitle>
        <Typography>{t("unsaved_data_prompt.title")}</Typography>
      </DialogTitle>
      <DialogContent>
        <Typography>{t("unsaved_data_prompt.content")}</Typography>
      </DialogContent>
      <DialogActions>
        <Grid container direction="row" spacing={2} justifyContent="flex-end">
          <Grid item>
            <Button onClick={onCancel} autoFocus variant="contained" color="inherit">
              {t("cancel")}
            </Button>
          </Grid>
          <Grid item>
            <Button onClick={onOk} variant="contained" color="primary">
              {t("continue")}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}
