import React, { useContext, useState } from "react"
import { HouseholdContainerMap } from "./household-container-map"
import {
  Chip,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material"
import { useTranslation } from "react-i18next"
import { Edit } from "@mui/icons-material"
import { HouseholdContainerEditDialog } from "./household-container-edit-dialog"
import { HouseholdContainerContext } from "../context/household-container-context"
import { HouseholdContainerWithId } from "../../../../api/graphql/queries/household-container-with-id"

interface IHouseholdContainerDetailsProps {}

const getContainerStatusChip = (container: HouseholdContainerWithId | null, active: string, comment: string) => {
  if (container?.rfid && !container?.comment) {
    return <Chip label={active} color="success" />
  } else if (container?.comment !== null) {
    return <Chip label={comment} color="error" />
  } else {
    return <Chip label=" - " />
  }
}

export const HouseholdContainerDetails: React.FC<IHouseholdContainerDetailsProps> = (props) => {
  const { container } = useContext(HouseholdContainerContext)
  const { t } = useTranslation()

  const [open, setOpen] = useState<boolean>(false)

  return (
    <>
      <HouseholdContainerEditDialog open={open} setOpen={setOpen} />
      <Grid container direction="row">
        <Grid item xs={5} sx={{ pr: 4 }}>
          <Grid display="flex" alignItems="center" justifyContent="space-between">
            <Grid>
              <Typography variant="h5">
                {container?.household.street} {container?.household.number}
              </Typography>
              <Typography variant="body1" color="gray">
                {container?.household.postal} {container?.household.place}
              </Typography>
            </Grid>
            <Grid>
              <IconButton color="primary" onClick={() => setOpen(true)}>
                <Edit />
              </IconButton>
            </Grid>
          </Grid>
          <TableContainer>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell sx={{ backgroundColor: "transparent" }}>
                    <Typography variant="body1" color="gray">
                      {t("household_details.status")}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ backgroundColor: "transparent" }}>
                    {getContainerStatusChip(
                      container,
                      t("household_details.active"),
                      container?.comment ? t(`household_details.table.comment_types.${container.comment}`) : "",
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ backgroundColor: "transparent" }}>
                    <Typography variant="body1" color="gray">
                      {t("household_details.rfid")}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ backgroundColor: "transparent" }}>
                    <Typography variant="body1">{container?.rfid ?? "-"}</Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ backgroundColor: "transparent" }}>
                    <Typography variant="body1" color="gray">
                      {t("household_details.material")}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ backgroundColor: "transparent" }}>
                    <Typography variant="body1">{container?.material?.name ?? "-"}</Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ backgroundColor: "transparent" }}>
                    <Typography variant="body1" color="gray">
                      {t("household_details.type")}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ backgroundColor: "transparent" }}>
                    <Typography variant="body1">{container?.type?.name ?? "-"}</Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={7}>
          <HouseholdContainerMap household={container?.household} />
        </Grid>
      </Grid>
    </>
  )
}
