import React, { FunctionComponent, useMemo } from "react"
import { useTourOverview } from "../../context/tour-overview-context"
import { CustomMap, IMarker } from "../../../../partials/maps/custom-map"
import { TourOverviewMapMarkerTooltip } from "./tour-overview-map-marker-tooltip"

interface ITourOverviewMapProps {}

export const TourOverviewMap: FunctionComponent<ITourOverviewMapProps> = (props) => {
  const { selectedStopId, setSelectedStopId, stopInfos } = useTourOverview()

  const markers = useMemo(() => {
    return stopInfos.map((stopInfo, index) => {
      return {
        id: stopInfo.id,
        index: index,
        lat: stopInfo.lat,
        lng: stopInfo.lng,
        stopId: stopInfo.stop_id,
        typeId: 0,
        status: stopInfo.status,
        sequenceNumber: index + 1,
        type: stopInfo.type,
        collection_point_id: stopInfo.type === "collection_point" ? stopInfo.id : undefined,
      }
    })
  }, [stopInfos])

  return (
    <CustomMap
      height={"400px"}
      markers={markers}
      isTour
      doRenderCustomInfoWindow
      renderCustomInfoWindow={(markerId) => <TourOverviewMapMarkerTooltip key={markerId} stopId={markerId} />}
      center={markers.find((marker) => marker.stopId === selectedStopId)}
      onMarkerClicked={(id: number) => {
        if (markers.find((marker) => marker.stopId === id)) {
          setSelectedStopId(id)
        }
      }}
      selectedMarkerId={(markers.find((marker) => marker.stopId === selectedStopId) || ({} as IMarker)).id}
      placeSearch
    />
  )
}
