import { Info, WarningRounded } from "@mui/icons-material"
import { Chip, Grid } from "@mui/material"
import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { ExportReason, StopStatus } from "../../../../api/graphql/graphql-global-types"
import { StopInfo } from "../../../../api/graphql/queries/get-tour-stats-with-id"

interface Props {
  stopInfo: StopInfo
}

export const StopTableRowHintCell: React.FC<Props> = (props) => {
  const { stopInfo } = props
  const { t } = useTranslation()

  const hintItems = useMemo(() => {
    const items: JSX.Element[] = []

    if (stopInfo.status === StopStatus.CANCELED) {
      items.push(
        <Chip
          icon={<WarningRounded fontSize="small" />}
          label={t(`tour_overview.table.hints.cancelled`)}
          sx={{
            pl: 1,
            backgroundColor: "#fae884",
            color: "#b58900",
            "& .MuiChip-icon": {
              color: "#b58900",
            },
          }}
        />,
      )
    }

    if (stopInfo.reason === ExportReason.MANUALLY_INSERTED_BY_DRIVER) {
      items.push(
        <Chip
          icon={<Info fontSize="small" />}
          label={t(`tour_overview.table.hints.driver_inserted`)}
          sx={{ pl: 1, backgroundColor: "#e0e0e0", color: "#424242" }}
        />,
      )
    }

    return items
  }, [stopInfo, t])

  return (
    <>
      {hintItems.length > 0 && (
        <Grid container spacing={1} alignItems="center" direction="column">
          {hintItems.map((item, idx) => (
            <Grid item key={`stopinfo_${stopInfo.id}_hint_${idx}`}>
              {item}
            </Grid>
          ))}
        </Grid>
      )}
      {hintItems.length === 0 && "-"}
    </>
  )
}
