import React, { useState } from "react"
import { InfoWindow, Marker } from "@react-google-maps/api"
import { Clusterer } from "@react-google-maps/marker-clusterer"
import { HouseholdForHouseholdContainerWithId } from "../../../../api/graphql/queries/household-container-with-id"
import { HouseholdContainerMarkerInfoContent } from "./household-container-marker-info-content"

interface IHouseholdMarkerProps {
  household: HouseholdForHouseholdContainerWithId
  clusterer: Clusterer
}

export const HouseholdContainerMarker: React.FC<IHouseholdMarkerProps> = (props) => {
  const { household, clusterer } = props

  const [isOpen, setIsOpen] = useState<boolean>(false)

  return (
    <Marker
      position={{ lat: household.latitude, lng: household.longitude }}
      clusterer={clusterer}
      onClick={() => {
        setIsOpen(true)
      }}
      icon={{
        fillColor: "white",
        fillOpacity: 0.75,
        scale: 1,
        strokeWeight: 3,
        strokeColor: "gray",
        path: "M-10,0a10,10 0 1,0 20,0a10,10 0 1,0 -20,0",
      }}
    >
      {isOpen && (
        <InfoWindow onCloseClick={() => setIsOpen(false)} onUnmount={() => setIsOpen(false)}>
          {<HouseholdContainerMarkerInfoContent household={household} />}
        </InfoWindow>
      )}
    </Marker>
  )
}
