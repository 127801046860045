import { Grid, Stack, Typography } from "@mui/material"
import React from "react"

interface Props {
  title: string
  description: string
  input: JSX.Element
}

export const CollectionGroupWizardGeneralSection: React.FC<Props> = (props) => {
  const { title, description, input } = props
  return (
    <Grid container direction="row" sx={{ px: 2 }} spacing={2}>
      <Grid item xs={12} md={4}>
        <Stack direction="column" spacing={2}>
          <Typography fontWeight={700} variant="h6">
            {title}
          </Typography>
          <Typography color="secondary.light">{description}</Typography>
        </Stack>
      </Grid>
      <Grid item xs={12} md={8}>
        {input}
      </Grid>
    </Grid>
  )
}
