import { gql } from "@apollo/client"
import {
  tourExportPreview,
  tourExportPreview_tourExportPreview,
  tourExportPreviewVariables,
  tourExportPreview_tourExportPreview_collectionPoints,
  tourExportPreview_tourExportPreview_materials,
} from "./types/tourExportPreview"

export const TOUR_EXPORT_PREVIEW_QUERY = gql`
  query tourExportPreview(
    $district_id: ID!
    $start_date: DateTime!
    $threshold: Int!
    $material_ids: [ID!]!
    $threshold_min: Int!
    $week_count: Int
    $reference_date: DateTime
    $version: AlgorithmType! = AGR
    $town_ids: [ID!]!
    $collection_group_id: ID
    $tour_without_containers: Boolean!
    $vehicles: [VehicleDaysInput!]!
  ) {
    tourExportPreview(
      district_id: $district_id
      start_date: $start_date
      threshold: $threshold
      material_ids: $material_ids
      threshold_min: $threshold_min
      week_count: $week_count
      reference_date: $reference_date
      version: $version
      town_ids: $town_ids
      collection_group_id: $collection_group_id
      tour_without_containers: $tour_without_containers
      vehicles: $vehicles
    ) {
      collectionPoints {
        id
        location_number
        description
        street
        place
        fixed_unload_interval
        postal
        town
        materials {
          material {
            id
            name
          }
          filllevel
          filllevel_percentage
          max_amount
        }
        last_emptying
      }
      materials {
        material {
          id
          name
        }
        filllevel
      }
      noRecycles
    }
  }
`

export type TourExportPreviewResult = tourExportPreview
export type TourExportPreviewVariables = tourExportPreviewVariables
export type TourExportPreview = tourExportPreview_tourExportPreview
export type TourExportPreviewCollectionPoint = tourExportPreview_tourExportPreview_collectionPoints
export type TourExportPreviewMaterial = tourExportPreview_tourExportPreview_materials
