import { useQuery } from "@apollo/client"
import { Box, Button, CircularProgress, Stack, Typography } from "@mui/material"
import React, { useCallback, useMemo } from "react"
import { useTranslation } from "react-i18next"
import {
  CollectionPointResult,
  GETCOLLECTIONPOINTWITHID_QUERY,
} from "../../../../api/graphql/queries/get-collection-points-with-id"
import { InfoWindowText } from "../../../partials/info-window/info-window-text"
import { useCollectionGroupWizardContext } from "../context/collection-group-wizard-context"

interface Props {
  id: number
}

export const CollectionGroupCollectionPointInfo: React.FC<Props> = (props) => {
  const { id } = props
  const { t } = useTranslation()
  const { formValues, setFormValues } = useCollectionGroupWizardContext()

  const { loading, data } = useQuery<CollectionPointResult>(GETCOLLECTIONPOINTWITHID_QUERY, {
    variables: {
      id,
    },
  })

  const collectionPoint = data?.getCollectionPointWithID

  const renderRow = useCallback((label: string, value: string) => {
    return <InfoWindowText label={label} value={value} />
  }, [])

  const isSelected = useMemo(() => (formValues?.collectionPointIds ?? []).includes(id), [formValues, id])

  const handleSelect = useCallback(() => {
    const selectedCps = formValues?.collectionPointIds ?? []
    setFormValues({ ...formValues, collectionPointIds: [...selectedCps, id] })
  }, [formValues, id, setFormValues])

  const handleDeselect = useCallback(() => {
    const selectedCps = formValues?.collectionPointIds ?? []
    setFormValues({ ...formValues, collectionPointIds: selectedCps.filter((cpId) => cpId !== id) })
  }, [formValues, id, setFormValues])

  return (
    <Box sx={{ minWidth: 250 }}>
      {loading && (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      )}
      {!loading && (
        <Stack direction="column">
          {renderRow(t("collection_points.table.id"), collectionPoint?.id ?? "-")}
          {renderRow(t("collection_points.table.description"), collectionPoint?.description ?? "-")}
          <Button sx={{ mt: 2 }} variant="contained" onClick={isSelected ? handleDeselect : handleSelect}>
            <Typography>{isSelected ? t("remove") : t("add")}</Typography>
          </Button>
        </Stack>
      )}
    </Box>
  )
}
