import { gql } from "@apollo/client"

export const UPSERT_COLLECTION_GROUP_MUTATION = gql`
  mutation UpsertCollectionGroup($collectionGroupInput: CollectionGroupInput!, $collectionGroupId: Float) {
    upsertCollectionGroup(collection_group_input: $collectionGroupInput, collection_group_id: $collectionGroupId) {
      id
      label
      message
      error
      materials {
        id
        name
      }
      district_id
      collection_points {
        id
      }
    }
  }
`
