import { gql } from "@apollo/client"
import {
  GetHouseholdContainerHistory,
  GetHouseholdContainerHistory_getHouseholdContainerHistory,
  GetHouseholdContainerHistory_getHouseholdContainerHistory_entries,
  GetHouseholdContainerHistoryVariables,
} from "./types/GetHouseholdContainerHistory"

export const HOUSEHOLD_CONTAINER_HISTORY_QUERY = gql`
  query GetHouseholdContainerHistory($getHouseholdContainerHistoryId: ID!, $page: Int!, $pageSize: Int!) {
    getHouseholdContainerHistory(id: $getHouseholdContainerHistoryId, page: $page, pageSize: $pageSize) {
      totalPages
      totalEntries
      page
      pageSize
      entries {
        id
        changeType
        date
        field
        oldValue
        newValue
      }
    }
  }
`

export type HouseholdContainerHistoryResult = GetHouseholdContainerHistory
export type HouseholdContainerHistoryVariables = GetHouseholdContainerHistoryVariables
export type HouseholdContainerHistory = GetHouseholdContainerHistory_getHouseholdContainerHistory
export type EntriesForHouseholdContainerHistory = GetHouseholdContainerHistory_getHouseholdContainerHistory_entries
