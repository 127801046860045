import { Grid, Stack, useTheme } from "@mui/material"
import React from "react"
import { useTranslation } from "react-i18next"
import { PageHeading } from "../../partials/layout/heading/page-heading"
import { RegionSelect } from "../../partials/region-select/region-select"
import { HouseholdDischargesContextProvider } from "./context/household-discharges-context"
import { HouseholdDischargeMap } from "./partials/household-discharges-map"
import { HouseholdDischargesTable } from "./partials/household-discharges-table"
import { HouseholdTourSelector } from "./partials/household-discharges-tour-selector"

export const HouseholdDischargesPage: React.FC = () => {
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <HouseholdDischargesContextProvider>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <PageHeading>{t("household_discharges.discharges")}</PageHeading>
        </Grid>
        <Grid item>
          <RegionSelect />
        </Grid>
        <Grid
          sx={{ p: `0 ${theme.spacing(1)}`, flex: "1 1 auto", overflowY: "auto", height: "100%" }}
          item
          container
          direction="row"
        >
          <Grid item>
            <HouseholdTourSelector />
          </Grid>
          <Grid
            sx={{
              padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
              overflowY: "auto",
              width: "100%",
              height: "100%",
              flex: "1",
            }}
            item
          >
            <Grid container direction="column">
              <Stack direction="column" spacing={1}>
                <HouseholdDischargeMap />
                <HouseholdDischargesTable />
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </HouseholdDischargesContextProvider>
  )
}
