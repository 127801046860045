import { gql } from "@apollo/client"
import {
  getHouseholdDischargesOfTour,
  getHouseholdDischargesOfTour_getHouseholdDischargesOfTour,
  getHouseholdDischargesOfTourVariables,
} from "./types/getHouseholdDischargesOfTour"

export const GET_HOUSEHOLD_DISCHARGES_OF_TOUR_QUERY = gql`
  query getHouseholdDischargesOfTour($getHouseholdDischargesOfTourId: Int!) {
    getHouseholdDischargesOfTour(id: $getHouseholdDischargesOfTourId) {
      id
      sequenceNumber
      date
      rfidHex
      weight
      householdContainer {
        id
        household {
          latitude
          longitude
          place
          number
          postal
          street
        }
      }
    }
  }
`

export type HouseholdDischargesOfTourResult = getHouseholdDischargesOfTour
export type HouseholdDischargesOfTourVariables = getHouseholdDischargesOfTourVariables
export type HouseholdDischargesOfTour = getHouseholdDischargesOfTour_getHouseholdDischargesOfTour
