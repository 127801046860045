import React, { FunctionComponent, useMemo, useState } from "react"
import {
  Grid,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TablePagination,
  TableBody,
  Typography,
  Button,
} from "@mui/material"
import { useTranslation } from "react-i18next"
import { useUploadCollectionPointsContext } from "./upload-collection-points-context"
import { usePagination } from "../../../../../hooks/use-pagination"
import { UploadCollectionPointsConfirmDialog } from "./upload-collection-points-confirm"
import { DuplicateAction, useDuplicateHandler } from "./use-duplicate-handler-hook"
import { toast } from "react-toastify"
import { CollectionPointForUpload } from "../../../../../api/graphql/queries/validate-collection-points-file"
import { useSelectedRegion } from "../../../../../hooks/use-selected-region"
import { useNavigate } from "react-router-dom"

interface IUploadCollectionPointsTableProps {}

export const UploadCollectionPointsTable: FunctionComponent<IUploadCollectionPointsTableProps> = (props) => {
  const { t } = useTranslation()
  const { collectionPoints, uploadCollectionPoints, reset } = useUploadCollectionPointsContext()
  const { regionName } = useSelectedRegion()

  const isDuplicate = (cp: CollectionPointForUpload): boolean => !!cp.collectionPointId
  const { current, next, all, finished, included } = useDuplicateHandler({
    toProcess: collectionPoints.filter(isDuplicate),
    included: collectionPoints.filter((cp) => !isDuplicate(cp)),
  })
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState<boolean>(
    collectionPoints.filter(isDuplicate).length > 0,
  )

  const navigate = useNavigate()

  const collectionPointsToShow = useMemo(
    () => (finished ? included : collectionPoints),
    [finished, included, collectionPoints],
  )

  const { count, currentPage, pageSize, setCurrentPage, setPageSize, entries } = usePagination(collectionPointsToShow)

  const handleSubmit = () => {
    if (current) return setIsConfirmDialogOpen(true)
    // next() is a noop if current is undefined.
    const { included } = next(DuplicateAction.Exclude)
    if (included.length > 0) uploadCollectionPoints(included)
    else handleEmptyInput()
  }

  const handleEmptyInput = () => {
    toast.warn(t("collection_point_administration.upload.empty"))
    reset()
  }

  const handleConfirm = (action: DuplicateAction, forAll: boolean) => {
    if (forAll) {
      const { included } = all(action)
      if (included.length > 0) setIsConfirmDialogOpen(false)
      else handleEmptyInput()
    } else {
      const { done, included } = next(action)
      if (done) {
        if (included.length > 0) setIsConfirmDialogOpen(false)
        else handleEmptyInput()
      }
    }
  }

  const handleClose = () => {
    navigate(-1)
  }

  return (
    <Grid container direction="column" spacing={2}>
      <UploadCollectionPointsConfirmDialog
        open={isConfirmDialogOpen}
        current={current}
        onClose={handleClose}
        onConfirm={handleConfirm}
      />
      <Grid item>
        <Typography variant="h5">
          {`${regionName.toUpperCase()}  > `}
          <b>
            {t("collection_point_administration.upload.collection_point_count", {
              collection_points_count: collectionPointsToShow.length,
            })}
          </b>
        </Typography>
      </Grid>
      <Grid item>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">{t("collection_point_administration.upload.table.description")}</TableCell>
                <TableCell align="center">{t("collection_point_administration.upload.table.street")}</TableCell>
                <TableCell align="center">{t("collection_point_administration.upload.table.town")}</TableCell>
                <TableCell align="center">{t("collection_point_administration.upload.table.latitude")}</TableCell>
                <TableCell align="center">{t("collection_point_administration.upload.table.longitude")}</TableCell>
                <TableCell align="center">
                  {t("collection_point_administration.upload.table.number_of_containers")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {entries.map((cp, idx) => (
                <TableRow key={idx} hover onClick={() => null}>
                  <TableCell align="center">{cp.description || "-"}</TableCell>
                  <TableCell align="center">{cp.street || "-"}</TableCell>
                  <TableCell align="center">{cp.town}</TableCell>
                  <TableCell align="center">{cp.latitude}</TableCell>
                  <TableCell align="center">{cp.longitude}</TableCell>
                  <TableCell align="center">{cp.containers.length}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            count={count}
            rowsPerPage={pageSize}
            page={currentPage}
            onPageChange={(_, page) => setCurrentPage(page)}
            onRowsPerPageChange={(event) => setPageSize(parseInt(event.target.value, 10))}
          />
        </TableContainer>
      </Grid>
      <Grid item container direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
        <Grid item>
          <Button
            sx={{ width: 200 }}
            type="button"
            variant="contained"
            onClick={() => navigate(-1)}
            fullWidth
            color="inherit"
          >
            {t("collection_point_administration.upload.cancel")}
          </Button>
        </Grid>
        <Grid item>
          <Button
            sx={{ width: 200 }}
            type="button"
            variant="contained"
            onClick={handleSubmit}
            fullWidth
            color="primary"
          >
            {t("collection_point_administration.upload.import")}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}
