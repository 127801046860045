import { ArrowForwardOutlined } from "@mui/icons-material"
import { Chip, Stack, TableRow, Tooltip, Typography, useTheme } from "@mui/material"
import React from "react"
import { useTranslation } from "react-i18next"
import { CollectionGroup } from "../../../../api/graphql/queries/get-collection-groups"
import { SmoothCell } from "../../../partials/table/smooth-cell"
import { useNavigate } from "react-router-dom"
import { PATH, replaceUriParameter } from "../../../../router/router"

interface CollectionGroupOverviewTableRowProps {
  group: CollectionGroup
}

export const CollectionGroupOverviewTableRow: React.FC<CollectionGroupOverviewTableRowProps> = (props) => {
  const { group } = props
  const sortedMaterials = group.materials ? [...group.materials].sort((a, b) => a.name.localeCompare(b.name)) : null

  const { t } = useTranslation()
  const theme = useTheme()
  const navigate = useNavigate()
  const url = replaceUriParameter(`${PATH.COLLECTION_GROUP_EDIT_PAGE.route}`, "collectionGroupId", String(group.id))

  const maxVisibleMaterials = 3
  const materialsToShow = sortedMaterials ? sortedMaterials.slice(0, maxVisibleMaterials) : null
  const remainingMaterialsCount = sortedMaterials ? sortedMaterials.length - maxVisibleMaterials : null

  return (
    <>
      <TableRow key={`collection_group_${group.id}`}>
        <SmoothCell>
          <Typography>{group.label}</Typography>
        </SmoothCell>
        <SmoothCell>
          <Typography>{group.district?.name ?? ""}</Typography>
        </SmoothCell>
        <SmoothCell>
          <Stack direction="row" spacing={1}>
            {sortedMaterials &&
              materialsToShow &&
              materialsToShow.map((material, index) => <Chip key={index} label={material.name} />)}
            {sortedMaterials != null && remainingMaterialsCount != null && remainingMaterialsCount > 0 && (
              <Tooltip
                title={sortedMaterials
                  .slice(maxVisibleMaterials)
                  .map((material) => material.name)
                  .join(", ")}
              >
                <Chip color="primary" label={`+${remainingMaterialsCount}`} />
              </Tooltip>
            )}
          </Stack>
        </SmoothCell>
        <SmoothCell align="right" sx={{ width: theme.spacing(6) }}>
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            justifyContent="flex-end"
            sx={{ "&:hover": { cursor: "pointer" } }}
            onClick={() => navigate(url)}
          >
            <Tooltip title={t("collection_group_overview.table.edit")}>
              <>
                <Typography fontWeight={700}>{t("collection_group_overview.details")}</Typography>
                <ArrowForwardOutlined />
              </>
            </Tooltip>
          </Stack>
        </SmoothCell>
      </TableRow>
    </>
  )
}
