import React, { useState, useContext, createContext, useMemo } from "react"
import {
  GETMINIMAL_TOURS_WITH_ASSID_QUERY,
  MinimalTour,
  MinimalToursResult,
  MinimalToursVariables,
} from "../api/graphql/queries/get-minimal-tours-with-region-id"
import { useRefetch } from "./refetch-context"
import { useQuery } from "@apollo/client"
import moment from "moment"
import { useSelectedRegion } from "../hooks/use-selected-region"

interface ISelectedTourContextType {
  selectedTour: MinimalTour | undefined
  setSelectedTour: (tour: MinimalTour | undefined) => void
  tours: MinimalTour[]
  toursLoading: boolean
  toursError: Error | undefined
  dateFrom: moment.Moment | null
  setDateFrom: (date: moment.Moment | null) => void
  dateTo: moment.Moment | null
  setDateTo: (date: moment.Moment | null) => void
}

export const SelectedTourContext = createContext<ISelectedTourContextType>({} as ISelectedTourContextType)

export const SelectedTourProvider = (props: any) => {
  const value = useSelectedTourProvider()

  return <SelectedTourContext.Provider value={value}>{props.children}</SelectedTourContext.Provider>
}

export const useSelectedTour = () => {
  return useContext(SelectedTourContext)
}

const useSelectedTourProvider = (): ISelectedTourContextType => {
  const [selectedTour, setSelectedTour] = useState<MinimalTour | undefined>(undefined)
  const [dateFrom, setDateFrom] = useState<moment.Moment | null>(moment().utc().startOf("day"))
  const [dateTo, setDateTo] = useState<moment.Moment | null>(null)
  const { needToRefetch, setNeedToRefetch } = useRefetch()

  const { variables, skip } = useSelectedRegion()

  const { loading, data, error } = useQuery<MinimalToursResult, MinimalToursVariables>(
    GETMINIMAL_TOURS_WITH_ASSID_QUERY,
    {
      fetchPolicy: needToRefetch ? "network-only" : "cache-and-network",
      onCompleted: () => setNeedToRefetch(false),
      variables: {
        id: Number(variables.id),
        type: variables.type,
        from: dateFrom && dateFrom.clone().add(dateFrom.utcOffset(), "minutes").toDate(),
        to: dateTo && dateTo.clone().add(dateTo.utcOffset(), "minutes").toDate(),
      },
      skip,
    },
  )

  const tours = useMemo(
    () => data?.getToursWithRegionId.slice().sort((a, b) => moment(a.date).diff(moment(b.date))) || [],
    [data],
  )

  return {
    selectedTour,
    setSelectedTour,
    tours,
    toursLoading: loading,
    toursError: error,
    dateFrom,
    dateTo,
    setDateFrom,
    setDateTo,
  }
}
