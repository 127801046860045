import { gql } from "@apollo/client"
import { UpdateHousehold, UpdateHousehold_updateHousehold, UpdateHouseholdVariables } from "./types/UpdateHousehold"

export const UPDATE_HOUSEHOLD_MUTATION = gql`
  mutation UpdateHousehold(
    $updateHouseholdId: ID!
    $street: String!
    $number: String!
    $postal: Int!
    $place: String!
  ) {
    updateHousehold(id: $updateHouseholdId, street: $street, number: $number, postal: $postal, place: $place) {
      id
      street
      number
      postal
      place
    }
  }
`

export type UpdatedHouseholdResult = UpdateHousehold
export type UpdatedHousehold = UpdateHousehold_updateHousehold
export type UpdatedHouseholdVariables = UpdateHouseholdVariables
