import { gql } from "@apollo/client"
import {
  getCollectionGroups,
  getCollectionGroups_getCollectionGroups,
  getCollectionGroupsVariables,
  getCollectionGroups_getCollectionGroups_entries,
} from "./types/getCollectionGroups"
export const GETCOLLECTIONGROUPS_QUERY = gql`
  query getCollectionGroups($page: Int!, $pagesize: Int!, $orderBy: String, $orderDirection: String) {
    getCollectionGroups(page: $page, pagesize: $pagesize, orderBy: $orderBy, orderDirection: $orderDirection) {
      entries {
        id
        label
        materials {
          id
          name
          translation_key
        }
        district {
          id
          name
        }
      }
      page
      pageSize
      totalEntries
      totalPages
    }
  }
`

export type CollectionGroupsResult = getCollectionGroups
export type CollectionGroupsVariables = getCollectionGroupsVariables
export type CollectionGroups = getCollectionGroups_getCollectionGroups
export type CollectionGroup = getCollectionGroups_getCollectionGroups_entries
