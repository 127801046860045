import React, { FunctionComponent, useState, useContext, useMemo } from "react"
import { Grid, Typography, Button, useTheme } from "@mui/material"
import { useTranslation } from "react-i18next"
import { GETMATERIALS_QUERY, Material, MaterialsResult } from "../../../../api/graphql/queries/get-materials"
import { useQuery } from "@apollo/client"
import { Add, Cancel } from "@mui/icons-material"
import { OutlinedSelect } from "../../../partials/layout/selection/outlined-select"
import { SelectPair } from "../../../partials/layout/selection/select-pair"
import { TourGenerationContext } from "../../../../context/tour-generation-context"

interface ITourGenerationMaterialsProps {}

export const TourGenerationMaterials: FunctionComponent<ITourGenerationMaterialsProps> = (props) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const [hoveredIndex, setHoveredIndex] = useState<number>(-1)

  const { data: materialsData } = useQuery<MaterialsResult>(GETMATERIALS_QUERY)

  const { materials, setMaterials, tourWithoutContainers, district, visibleSelect, collectionGroup } = useContext(TourGenerationContext)
  const useGroupMaterials = useMemo(() => district?.allow_tourgeneration_group_select && visibleSelect === "group", [district, visibleSelect]) 

  const getMaterialsToUse = (allMaterials: Material[]) => {
    const materialsToUseId = useGroupMaterials && collectionGroup
    ? (district?.collection_groups ?? []).filter(g => g.id === collectionGroup.id).flatMap((group) => group.materials ?? []).flatMap((material) => material.id)
    : []
    const uniqueGroupMaterialIds = Array.from(new Set(materialsToUseId))
    const materialsToUse = uniqueGroupMaterialIds.length > 0 ? allMaterials.filter((m) => uniqueGroupMaterialIds.includes(m.id)) : allMaterials
    return materialsToUse
  }

  const getNextAvailableMaterial = () => {
    if (!materialsData?.getMaterials) {
      return undefined
    }
    const loadedMaterials = materialsData.getMaterials;

    if(useGroupMaterials && !collectionGroup) return undefined
    const materialsToUse = getMaterialsToUse(loadedMaterials)

    const mats = materialsToUse.find(
      (material) => !materials.find((selectedMaterial) => material.id === selectedMaterial.id),
    )

    return mats;
  }

  const getMaterialsForSelect = (selectedMaterial: Material): SelectPair[] => {
    if (!materialsData?.getMaterials) {
      return []
    }

    const materialsToUse = getMaterialsToUse(materialsData.getMaterials)
    return materialsToUse
      .filter(
        (material) =>
          material.id === selectedMaterial.id.toString() || !materials.find((m) => m.id.toString() === material.id),
      )
      .map((material) => new SelectPair(material.id, material.name))
  }

  const updateMaterials = (newValue: string, index: number): void => {
    const copy: Material[] = JSON.parse(JSON.stringify(materials))
    copy[index] = materialsData!.getMaterials.find((material) => material.id.toString() === newValue) as Material

    setMaterials(copy)
  }

  const disableAddButton = useMemo<boolean>(
    () => !materialsData || materials.length === materialsData.getMaterials.length || tourWithoutContainers,
    [materialsData, materials, tourWithoutContainers],
  )

  return (
    <Grid item container direction="column" spacing={2} sx={{ p: 2 }}>
      <Grid item container direction="row" justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography sx={{ fontWeight: "bold" }}>{t("tour_generation.data.materials")}</Typography>
        </Grid>
        <Grid item>
          <Button
            type="button"
            variant="outlined"
            sx={{
              borderWidth: 0.5,
              borderRadius: 1,
              borderColor: "primary.main",
              margin: `${theme.spacing(1)} 0 ${theme.spacing(1)} 0`,
            }}
            onClick={() => {
              const nextAvailableMaterial = getNextAvailableMaterial()
              if (!nextAvailableMaterial) {
                return
              }
              const newMaterials = [...materials, nextAvailableMaterial]
              setMaterials(newMaterials)
            }}
            disabled={disableAddButton}
            fullWidth
          >
            <Add sx={{ color: !disableAddButton ? "primary.main" : undefined }} />
          </Button>
        </Grid>
      </Grid>
      <Grid item container direction="row" spacing={2}>
        {materials.length ? (
          materials.map((material, index) => (
            <Grid
              item
              container
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
              spacing={1}
              xs={6}
              key={`material${index}`}
              onMouseEnter={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(-1)}
            >
              <Grid item xs>
                <OutlinedSelect
                  options={getMaterialsForSelect(material)}
                  name={t("tour_generation.data.material_number", { number: index + 1 })}
                  onValueChange={(newValue) => updateMaterials(newValue, index)}
                  value={material.id.toString()}
                  disabled={tourWithoutContainers}
                />
              </Grid>
              {index === hoveredIndex && !tourWithoutContainers && (
                <Grid item onClick={() => setMaterials(materials.filter((_, i) => index !== i))}>
                  <Grid item sx={{ cursor: "pointer" }}>
                    <Cancel color="error" width={24} height={24} />
                  </Grid>
                </Grid>
              )}
            </Grid>
          ))
        ) : (
          <Grid item sx={{ textAlign: "center" }} xs={12}>
            <Typography>{t("tour_generation.data.no_materials_selected")}</Typography>
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}
