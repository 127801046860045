import { gql } from "@apollo/client"
import {
  getHouseholdTours,
  getHouseholdTours_getHouseholdTours,
  getHouseholdToursVariables,
} from "./types/getHouseholdTours"

export const GET_HOUSEHOLD_TOURS_QUERY = gql`
  query getHouseholdTours($districtId: Int!, $to: DateTime, $from: DateTime) {
    getHouseholdTours(districtId: $districtId, to: $to, from: $from) {
      id
      tourDate
      vehicleIdIdentSystem
      vehicleTypePlate
      numDischarges
      matched
      materials {
        id
        name
      }
      district {
        id
        name
      }
      dischargeWarningsCount
    }
  }
`

export type HouseholdToursResult = getHouseholdTours
export type HouseholdToursVariables = getHouseholdToursVariables
export type HouseholdTour = getHouseholdTours_getHouseholdTours
