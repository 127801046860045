import { ApolloError, useQuery } from "@apollo/client"
import React, { FC, PropsWithChildren, useCallback, useMemo, useState } from "react"
import { OrderBy } from "../../../../api/graphql/graphql-global-types"
import {
  CollectionGroup,
  CollectionGroupsResult,
  CollectionGroupsVariables,
  GETCOLLECTIONGROUPS_QUERY,
} from "../../../../api/graphql/queries/get-collection-groups"

const DEFAULT_PAGINATION: ICollectionGroupTablePagination = {
  page: 0,
  pagesize: 10,
  orderBy: "label",
  orderDirection: OrderBy.ASC,
}

const DEFAULT_FILTER: ICollectionGroupOverviewFilter = {
  pagination: DEFAULT_PAGINATION,
}

interface ICollectionGroupTablePagination {
  page: number
  pagesize: number
  orderBy: string | null
  orderDirection: string | null
}

interface ICollectionGroupOverviewFilter {
  pagination: ICollectionGroupTablePagination
}

interface ICollectionGroupOverviewTableContext {
  collectionGroups: any[] // TODO type it
  totalResults: number
  loading: boolean
  error: ApolloError | undefined

  filter: ICollectionGroupOverviewFilter
  updateFilter: (newFilter: ICollectionGroupOverviewFilter) => void
  setPagination: (value: ICollectionGroupTablePagination) => void
}

export const CollectionGroupOverviewTableContext = React.createContext<ICollectionGroupOverviewTableContext>(
  {} as ICollectionGroupOverviewTableContext,
)

export const CollectionGroupOverviewTableContextProvider: FC<PropsWithChildren<Record<never, never>>> = (props) => {
  const [totalResults, setTotalResults] = useState<number>(0)
  const [filter, setFilter] = useState<ICollectionGroupOverviewFilter>(DEFAULT_FILTER)

  const updateFilter = useCallback(
    (filter: ICollectionGroupOverviewFilter) => setFilter({ ...filter, pagination: DEFAULT_PAGINATION }),
    [],
  )

  const setPagination = useCallback(
    (pagination: ICollectionGroupTablePagination) => {
      setFilter({ ...filter, pagination })
    },
    [filter],
  )

  const { data, loading, error, called } = useQuery<CollectionGroupsResult, CollectionGroupsVariables>(
    GETCOLLECTIONGROUPS_QUERY,
    {
      variables: {
        page: filter.pagination.page,
        pagesize: filter.pagination.pagesize,
        orderBy: filter.pagination.orderBy,
        orderDirection: filter.pagination.orderDirection,
      },
      onCompleted: (result) => setTotalResults(result.getCollectionGroups.totalEntries),
      onError: () => setTotalResults(0),
    },
  )

  const collectionGroups: CollectionGroup[] = useMemo(
    () =>
      data?.getCollectionGroups.entries.map((entry) => ({
        ...entry,
      })) || [],
    [data],
  )

  return (
    <CollectionGroupOverviewTableContext.Provider
      value={{
        collectionGroups,
        loading: loading || !called,
        error,
        totalResults,
        updateFilter,
        filter,
        setPagination,
      }}
    >
      {props.children}
    </CollectionGroupOverviewTableContext.Provider>
  )
}
