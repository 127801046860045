import { TableCell, TableCellProps } from "@mui/material"
import React from "react"

interface Props extends TableCellProps {}

export const SmoothCell: React.FC<Props> = (props) => {
  const { children, sx, ...rest } = props

  return (
    <TableCell sx={{ ...sx, border: "none" }} {...rest}>
      {children}
    </TableCell>
  )
}
