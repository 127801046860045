import {
  Box,
  Checkbox,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import {
  getCollectionPointsWithRegionID_getCollectionPointsWithRegionID_entries,
  getCollectionPointsWithRegionID_getCollectionPointsWithRegionID_entries_collection_groups,
} from "../../../../api/graphql/queries/types/getCollectionPointsWithRegionID"
import { SmoothCell } from "../../../partials/table/smooth-cell"
import { useCollectionGroupWizardContext } from "../context/collection-group-wizard-context"
import { CollectionGroupDetailPopover } from "./collection-group-detail-popover"

type CollectionPoint = getCollectionPointsWithRegionID_getCollectionPointsWithRegionID_entries
export type CollectionPointCollectionGroup =
  getCollectionPointsWithRegionID_getCollectionPointsWithRegionID_entries_collection_groups

export const CollectionGroupCollectionPointsTable: React.FC = () => {
  const { t } = useTranslation()
  const { collectionPointsForTable, cpFilter, setCpFilter, cpForTableLoading, loading, formValues, setFormValues } =
    useCollectionGroupWizardContext()
  const [anchorEl, setAnchorEl] = useState<Element | undefined>()
  const [collectionGroups, setCollectionGroups] = useState<CollectionPointCollectionGroup[]>([])

  const getMaxHeight = useCallback(() => {
    const wizardIndicatorHeight = 100
    const headerHeight = 73
    const filterBarHeight = 72
    const tableHeaderRow = 75
    const buttonWizardRow = 68

    const height =
      window.innerHeight - wizardIndicatorHeight - headerHeight - filterBarHeight - tableHeaderRow - buttonWizardRow
    return height > 0 ? height : 0
  }, [])

  const [maxHeight, setMaxHeight] = useState(getMaxHeight())

  useEffect(() => {
    const handleResize = () => {
      setMaxHeight(getMaxHeight())
    }
    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [getMaxHeight])

  const entries = useMemo(() => collectionPointsForTable?.entries ?? [], [collectionPointsForTable])

  const getCollectionPointAddress = (
    collectionPoint: getCollectionPointsWithRegionID_getCollectionPointsWithRegionID_entries,
  ) => {
    const addressParts = []
    if (collectionPoint.street) {
      addressParts.push(collectionPoint.street)
    }
    if (collectionPoint.cadastral_nr) {
      addressParts.push(collectionPoint.cadastral_nr)
    }
    if (collectionPoint.cadastral_name) {
      addressParts.push(collectionPoint.cadastral_name)
    }

    return addressParts.length > 0 ? addressParts.join(", ") : "-"
  }

  const isSelected = (cpId: string) => {
    return formValues?.collectionPointIds?.includes(Number(cpId)) ?? false
  }

  const getGroupCount = useCallback(
    (groups: CollectionPointCollectionGroup[] | null) => {
      if (!groups) return 0
      const selectedMaterialsIds = formValues?.materialIds ?? []
      return groups.filter((group) => {
        const materialIds = (group.materials ?? []).map((m) => m.id)
        return (
          selectedMaterialsIds.length === materialIds.length &&
          materialIds.every((id) => selectedMaterialsIds.includes(Number(id)))
        )
      }).length
    },
    [formValues],
  )

  const handleGroupInfoClick = useCallback(
    (e: React.MouseEvent<HTMLSpanElement, MouseEvent>, collectionPoint: CollectionPoint) => {
      const count = getGroupCount(collectionPoint.collection_groups)
      if (count === 0) return
      const selectedMaterialsIds = formValues?.materialIds ?? []

      const filtered = (collectionPoint.collection_groups ?? [])
        .filter((group) => {
          const groupMaterials = group.materials ?? []
          return (
            groupMaterials.every((m) => selectedMaterialsIds.includes(Number(m.id))) &&
            groupMaterials.length === selectedMaterialsIds.length
          )
        })
        .sort((a, b) => a.label.localeCompare(b.label))

      setCollectionGroups(filtered)
      setAnchorEl(e.currentTarget)
    },
    [formValues, getGroupCount],
  )

  const handleCheckboxChange = useCallback(
    (checked: boolean, collectionPoint: CollectionPoint) => {
      if (checked) {
        setFormValues({
          ...formValues,
          collectionPointIds: [...(formValues?.collectionPointIds ?? []), Number(collectionPoint.id)],
        })
      } else {
        setFormValues({
          ...formValues,
          collectionPointIds: (formValues?.collectionPointIds ?? []).filter((id) => id !== Number(collectionPoint.id)),
        })
      }
    },
    [formValues, setFormValues],
  )

  const handleAllCheckboxChange = useCallback(
    (checked: boolean) => {
      if (checked) {
        setFormValues({
          ...formValues,
          collectionPointIds: entries.map((cp) => Number(cp.id)),
        })
      } else {
        setFormValues({
          ...formValues,
          collectionPointIds: [],
        })
      }
    },
    [entries, formValues, setFormValues],
  )

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      {anchorEl && (
        <CollectionGroupDetailPopover
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          collectionGroups={collectionGroups}
        />
      )}
      <TableContainer
        id="collection-points-for-collection-group-table"
        sx={{ minHeight: 150, maxHeight: maxHeight, overscrollBehavior: "contain" }}
      >
        <Table aria-label="collection points table" stickyHeader>
          <TableHead>
            <TableRow id="header-table-row">
              <SmoothCell align="center">
                <Checkbox
                  onChange={(event) => handleAllCheckboxChange(event.target.checked)}
                  checked={entries.length > 0 && entries.every((cp) => isSelected(cp.id))}
                />
              </SmoothCell>
              <SmoothCell align="center">
                <Typography fontWeight={700}>{t("collection_groups.mutate_page.address").toUpperCase()}</Typography>
              </SmoothCell>
              <SmoothCell align="center">
                <Typography fontWeight={700}>{t("collection_groups.mutate_page.description").toUpperCase()}</Typography>
              </SmoothCell>
              <SmoothCell align="center">
                <Typography fontWeight={700}>{t("collection_groups.mutate_page.town").toUpperCase()}</Typography>
              </SmoothCell>
              <SmoothCell align="center">
                <TableSortLabel
                  direction={cpFilter.sortByGroupCountAsc ? "asc" : "desc"}
                  onClick={() => setCpFilter({ ...cpFilter, sortByGroupCountAsc: !cpFilter.sortByGroupCountAsc })}
                >
                  <Typography fontWeight={700}>{t("collection_groups.mutate_page.groups").toUpperCase()}</Typography>
                </TableSortLabel>
              </SmoothCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {cpForTableLoading && !loading && (
              <TableRow>
                <SmoothCell colSpan={5} align="center">
                  <Box display="flex" justifyContent="center">
                    <CircularProgress />
                  </Box>
                </SmoothCell>
              </TableRow>
            )}
            {entries.length === 0 && !cpForTableLoading && (
              <TableRow>
                <SmoothCell colSpan={5} align="center">
                  <Box display="flex" justifyContent="center">
                    <Typography>{t("collection_group_overview.table.no_data")}</Typography>
                  </Box>
                </SmoothCell>
              </TableRow>
            )}
            {!cpForTableLoading &&
              entries.length > 0 &&
              entries.map((collectionPoint) => (
                <TableRow key={collectionPoint.id} sx={{ "&:hover": { backgroundColor: "#f5f5f5" } }}>
                  <SmoothCell align="center">
                    <Checkbox
                      checked={isSelected(collectionPoint.id)}
                      onChange={(ev) => handleCheckboxChange(ev.target.checked, collectionPoint)}
                    />
                  </SmoothCell>
                  <SmoothCell align="center">
                    <Typography>{getCollectionPointAddress(collectionPoint)}</Typography>
                  </SmoothCell>
                  <SmoothCell align="center">
                    <Typography>{collectionPoint.description}</Typography>
                  </SmoothCell>
                  <SmoothCell align="center">
                    <Typography>{collectionPoint.town?.name ?? ""}</Typography>
                  </SmoothCell>
                  <SmoothCell align="center">
                    <Typography onClick={(e) => handleGroupInfoClick(e, collectionPoint)}>
                      ({getGroupCount(collectionPoint.collection_groups)})
                    </Typography>
                  </SmoothCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        sx={{ borderTop: "1px solid #F2F2F2" }}
        rowsPerPageOptions={[5, 10, 25, 100, 500]}
        component="div"
        count={collectionPointsForTable?.totalEntries ?? 0}
        rowsPerPage={cpFilter.pageSize}
        page={cpFilter.page}
        onPageChange={(_, page) => setCpFilter({ ...cpFilter, page })}
        onRowsPerPageChange={(event) => setCpFilter({ ...cpFilter, pageSize: parseInt(event.target.value, 10) })}
      />
    </Paper>
  )
}
