import { Add } from "@mui/icons-material"
import { Button, Grid, Typography } from "@mui/material"
import React from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { PATH } from "../../../../router/router"

export const CollectionGroupOverviewHeader: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const redirectToCollectionGroupAdd = () => {
    navigate(PATH.COLLECTION_GROUP_ADD_PAGE.route)
  }

  return (
    <Grid container justifyContent="space-between">
      <Grid item>
        <Typography variant="h4" fontWeight={700}>
          {t("collection_group_overview.heading")}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {t("collection_group_overview.description")}
        </Typography>
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          type="button"
          color="primary"
          sx={{ borderRadius: 99, textTransform: "none" }}
          onClick={redirectToCollectionGroupAdd}
        >
          <Add />
          {t("collection_group_overview.new_group")}
        </Button>
      </Grid>
    </Grid>
  )
}
