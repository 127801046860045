import React from "react"
import { CollectionGroupWizard } from "./partials/collection-group-wizard"
import { CollectionGroupWizardContextProvider, ModeOption } from "./context/collection-group-wizard-context"

export const CollectionGroupAddPage: React.FC = () => {
  return (
    <CollectionGroupWizardContextProvider mode={ModeOption.Add}>
      <CollectionGroupWizard/>
    </CollectionGroupWizardContextProvider>
  )
}
