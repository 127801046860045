import { MutationFunctionOptions, QueryLazyOptions, useLazyQuery, useMutation, useQuery } from "@apollo/client"
import { ExecutionResult } from "graphql"
import React, { useContext, createContext, useState, useMemo } from "react"
import {
  DeleteTourResult,
  DeleteTourVariables,
  DELETE_TOUR_MUTATION,
} from "../../../../api/graphql/mutations/delete-tour"
import { deleteTour, deleteTourVariables } from "../../../../api/graphql/mutations/types/deleteTour"
import {
  GetTourStatsWithIdVariables,
  GETTOURSTATS_WITH_ID_QUERY,
  TourStatsResult,
  StopInfo,
} from "../../../../api/graphql/queries/get-tour-stats-with-id"
import { useRefetch } from "../../../../context/refetch-context"
import lodash from "lodash"
import { useSelectedTour } from "../../../../context/selected-tour-context"
import { STOP_TYPE } from "../../../../api/graphql/graphql-global-types"
import { getTourStatsWithId } from "../../../../api/graphql/queries/types/getTourStatsWithId"
import {
  GetTourAsExcelFileResult,
  GetTourAsExcelFileVariables,
  GET_TOUR_AS_EXCEL_FILE_QUERY,
} from "../../../../api/graphql/queries/get-tour-as-excel-file"
import { getTourAsExcelFileVariables } from "../../../../api/graphql/queries/types/getTourAsExcelFile"
import { ApolloError } from "@apollo/client"
import { toast } from "react-toastify"
import i18next from "i18next"
import { DownloadManagerContext } from "../../../partials/download-manager/download-manager-context"
import moment from "moment"
import { useSelectedRegion } from "../../../../hooks/use-selected-region"

interface TourOverviewContextType {
  selectedStopId: number | undefined
  setSelectedStopId: (value: number | undefined) => void
  deleteTour: (
    options?: MutationFunctionOptions<deleteTour, deleteTourVariables>,
  ) => Promise<ExecutionResult<deleteTour>>
  tourStatsData: getTourStatsWithId | undefined
  tourStatsError: ApolloError | undefined
  tourStatsLoading: boolean
  getTourAsExcelFile: (options?: QueryLazyOptions<getTourAsExcelFileVariables> | undefined) => void
  excelLoading: boolean
  stopInfos: StopInfo[]
}

const TourOverviewContext = createContext<TourOverviewContextType>({} as TourOverviewContextType)

export const TourOverviewContextProvider = (props: any) => {
  const value = useTourOverviewProvider()
  return <TourOverviewContext.Provider value={value}>{props.children}</TourOverviewContext.Provider>
}

export const useTourOverview = () => {
  return useContext(TourOverviewContext)
}

const useTourOverviewProvider = (): TourOverviewContextType => {
  const { selectedTour } = useSelectedTour()
  const { variables, skip } = useSelectedRegion()
  const { setTriggerQuery } = useContext(DownloadManagerContext)

  const [selectedStopId, setSelectedStopId] = useState<number>()
  const [deleteTour] = useMutation<DeleteTourResult, DeleteTourVariables>(DELETE_TOUR_MUTATION)

  const { needToRefetch } = useRefetch()

  const { data: tourStatsData, error: tourStatsError, loading: tourStatsLoading } = useQuery<
    TourStatsResult,
    GetTourStatsWithIdVariables
  >(GETTOURSTATS_WITH_ID_QUERY, {
    fetchPolicy: needToRefetch ? "network-only" : "cache-and-network",
    variables: {
      id: !lodash.isNil(selectedTour) ? Number(selectedTour!.id) : -1,
      regionId: String(variables.id),
      regionType: variables.type,
    },
    skip: skip || selectedTour === undefined,
  })

  const showError = () => {
    toast.error(i18next.t("export.unknown_error"))
  }

  const onRequestCompleted = (data: GetTourAsExcelFileResult) => {
    if (!data.getTourAsExcelFile) {
      showError()
    }
    setTriggerQuery(true)
  }

  const [getTourAsExcelFile, { loading: excelLoading }] = useLazyQuery<
    GetTourAsExcelFileResult,
    GetTourAsExcelFileVariables
  >(GET_TOUR_AS_EXCEL_FILE_QUERY, {
    onCompleted: onRequestCompleted,
    onError: showError,
    fetchPolicy: "no-cache",
  })

  const stopInfos = useMemo(() => {
    if (!tourStatsData?.getTourStatsWithId?.stop_infos) {
      return []
    }

    const infos = tourStatsData.getTourStatsWithId.stop_infos
      .concat(tourStatsData.getTourStatsWithId.stopsInsertedByDriver)
      .sort((a, b) => {
        // sort by updated_at (date) and then by sequence_number
        return (
          moment(a.updated_at || new Date()).diff(moment(b.updated_at || new Date())) ||
          (a.sequence_number || 0) - (b.sequence_number || 0)
        )
      })

    const indexDeparturePoint = infos.findIndex(
      (info) => info.type.toUpperCase() === STOP_TYPE.DEPARTURE_POINT && info.sequence_number === 0,
    )
    if (indexDeparturePoint !== -1) {
      const departurePoint = infos[indexDeparturePoint]
      infos.splice(indexDeparturePoint, 1)
      infos.unshift(departurePoint)
    }

    return infos
  }, [tourStatsData])

  return {
    selectedStopId,
    setSelectedStopId,
    deleteTour,
    tourStatsData,
    tourStatsError,
    tourStatsLoading,
    getTourAsExcelFile,
    excelLoading,
    stopInfos,
  }
}
