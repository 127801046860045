import { gql } from "@apollo/client"
import {
  householdContainerWithId,
  householdContainerWithId_householdContainerWithId,
  householdContainerWithId_householdContainerWithId_household,
  householdContainerWithIdVariables,
} from "./types/householdContainerWithId"

export const HOUSEHOLD_CONTAINER_WITH_ID_QUERY = gql`
  query householdContainerWithId($id: ID!) {
    householdContainerWithId(id: $id) {
      id
      comment
      rfid
      household {
        id
        street
        number
        postal
        place
        latitude
        longitude
      }
      material {
        id
        name
        translation_key
      }
      type {
        id
        name
      }
    }
  }
`

export type HouseholdContainerWithIdResult = householdContainerWithId
export type HouseholdContainerWithIdVariables = householdContainerWithIdVariables
export type HouseholdContainerWithId = householdContainerWithId_householdContainerWithId
export type HouseholdForHouseholdContainerWithId = householdContainerWithId_householdContainerWithId_household
