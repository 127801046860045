import { gql } from "@apollo/client"
import { UpdateHouseholdContainer, UpdateHouseholdContainer_updateHouseholdContainer, UpdateHouseholdContainerVariables } from "./types/UpdateHouseholdContainer"

export const UPDATE_HOUSEHOLD_CONTAINER_QUERY = gql`
  mutation UpdateHouseholdContainer(
    $updateHouseholdContainerId: ID!
    $rfid: ID!
    $containerTypeId: ID!
    $materialId: ID!
  ) {
    updateHouseholdContainer(
      id: $updateHouseholdContainerId
      rfid: $rfid
      containerTypeId: $containerTypeId
      materialId: $materialId
    ) {
      rfid
      material {
        id
        name
    }
      type {
        id
        name
      }
    }
  }
`

export type UpdatedHouseholdContainerResult = UpdateHouseholdContainer
export type UpdatedHouseholdContainerVariables = UpdateHouseholdContainerVariables
export type UpdatedHouseholdContainer = UpdateHouseholdContainer_updateHouseholdContainer
