import { useMemo } from "react"
import { RegionType } from "../api/graphql/graphql-global-types"
import { useAssociationFilter } from "../context/AssociationFilterContext"
import { useDistrictFilter } from "../context/DistrictFilterContext"
import { UserService } from "../services/user-service"

export const useSelectedRegion = () => {
  const { selectedAssociation } = useAssociationFilter()
  const { selectedDistrict } = useDistrictFilter()

  return useMemo(
    () => ({
      regionName: (UserService.hasAssociationFilter() ? selectedAssociation?.name : selectedDistrict?.name) || "",
      variables: {
        id: UserService.hasAssociationFilter() ? String(selectedAssociation?.id) : String(selectedDistrict?.id),
        type: UserService.hasAssociationFilter() ? RegionType.ASSOCIATION : RegionType.DISTRICT,
      },
      skip:
        (UserService.hasAssociationFilter() && !selectedAssociation) || !selectedDistrict,
    }),
    [selectedAssociation, selectedDistrict],
  )
}
