import { default as lodash, default as _ } from "lodash"
import moment, { Moment } from "moment"
import React, { createContext, FunctionComponent, PropsWithChildren, useEffect, useMemo, useState } from "react"
import { AlgorithmType, Day, VehicleDaysInput } from "../api/graphql/graphql-global-types"
import { GenerateToursVariables } from "../api/graphql/mutations/generate-tours"
import { AvailableDisposalMerchantsPerDay } from "../api/graphql/queries/available-disposal-merchants-for-tour-generation"
import { ConcurrentTourParametersVariables } from "../api/graphql/queries/concurrent-tour-parameters"
import { DisposalTrailerLocation } from "../api/graphql/queries/get-disposal-trailer-locations"
import { DistrictCollectionGroup, DistrictTown, DistrictWithTowns } from "../api/graphql/queries/get-districts-with-towns"
import { Material } from "../api/graphql/queries/get-materials"
import { TourExportPreviewCollectionPoint } from "../api/graphql/queries/tour-export-preview"
import { IVehicleEntry } from "../components/pages/tour-generation/partials/tour-generation-vehicle-selection"
import { day, DayOfWeek, dayOfWeekToNumber, isSameWeekDay } from "../utils/day"
import { isVehicleActive } from "../utils/tourGeneration"
import { convertCollectionPointsToPreviewEntries } from "../utils/tourPreview"

export const maxTerminationTimeMinimum = moment("00:15", "HH:mm")

export enum TourGenerationSelectValue {
  Town = "town",
  Group = "group",
}

interface TourWeek {
  week: number
  weekStartDate: Date
  days: DayOfWeek[]
}

interface ITourGenerationContext {
  district: DistrictWithTowns | null
  setDistrict: (district: DistrictWithTowns | null) => void
  towns: DistrictTown[]
  setTowns: (towns: DistrictTown[]) => void
  collectionGroup: DistrictCollectionGroup | null
  setCollectionGroup: (collectionGroup: DistrictCollectionGroup | null) => void
  firstWeek: Moment
  setFirstWeek: (firstWeek: Moment) => void
  secondWeek: Moment
  setSecondWeek: (secondWeek: Moment) => void
  threshold: number | null
  setThreshold: (threshold: number | null) => void
  thresholdMin: number | null
  setThresholdMin: (thresholdMin: number | null) => void
  vehicles: IVehicleEntry[]
  setVehicles: (vehicles: IVehicleEntry[]) => void
  materials: Material[]
  setMaterials: (materials: Material[]) => void
  version: AlgorithmType
  setVersion: (version: AlgorithmType) => void
  maxTerminationTime: Moment
  setMaxTerminationTime: (maxTerminationTime: Moment) => void
  maxTerminationTimeValid: boolean
  maxIterations: number | null
  setMaxIterations: (maxIterations: number | null) => void
  scheduleDate: Moment | null
  setScheduleDate: (scheduleDate: Moment | null) => void
  referenceDate: Moment | null
  setReferenceDate: (referenceDate: Moment | null) => void
  referenceDateValid: boolean
  consideredTourDays: number | null
  setConsideredTourDays: (consideredTourDays: number | null) => void
  disposalTrailerLocations: DisposalTrailerLocation[]
  setDisposalTrailerLocations: (disposalTrailerLocation: DisposalTrailerLocation[]) => void
  tourWithoutContainers: boolean
  setTourWithoutContainers: (tourWithoutContainers: boolean) => void
  getGenerateToursVariables: (previewCollectionPoints: TourExportPreviewCollectionPoint[]) => GenerateToursVariables
  disposalMerchants: AvailableDisposalMerchantsPerDay
  setDisposalMerchants: (disposalMerchants: AvailableDisposalMerchantsPerDay) => void
  loadingDisposalMerchants: boolean
  setLoadingDisposalMerchants: (loadingDisposalMerchants: boolean) => void
  getConcurrentTourGenerationVariables: () => ConcurrentTourParametersVariables
  keepConcurrentGenerations: boolean
  setKeepConcurrentGenerations: (keepConcurrentGenerations: boolean) => void
  tourWeeks: TourWeek[]
  vehicleDaysInput: VehicleDaysInput[]
  visibleSelect: TourGenerationSelectValue | undefined
  setVisibleSelect: (visibleSelect: TourGenerationSelectValue | undefined) => void
}

export const TourGenerationContext = createContext<ITourGenerationContext>({
  district: null,
  setDistrict: () => null,
  towns: [],
  setTowns: () => null,
  collectionGroup: null,
  setCollectionGroup: () => null,
  firstWeek: moment(),
  setFirstWeek: () => null,
  secondWeek: moment().add(1, "week"),
  setSecondWeek: () => null,
  threshold: 80,
  setThreshold: () => null,
  thresholdMin: 60,
  setThresholdMin: () => null,
  vehicles: [],
  setVehicles: () => null,
  materials: [],
  setMaterials: () => null,
  version: AlgorithmType.TO2,
  setVersion: () => null,
  maxTerminationTime: moment("12:00", "HH:mm"),
  setMaxTerminationTime: () => null,
  maxTerminationTimeValid: false,
  maxIterations: null,
  setMaxIterations: () => null,
  scheduleDate: null,
  setScheduleDate: () => null,
  referenceDate: null,
  setReferenceDate: () => null,
  referenceDateValid: false,
  consideredTourDays: 0,
  setConsideredTourDays: () => null,
  disposalTrailerLocations: [],
  setDisposalTrailerLocations: () => null,
  tourWithoutContainers: false,
  setTourWithoutContainers: () => null,
  getGenerateToursVariables: () => ({}) as GenerateToursVariables,
  disposalMerchants: [],
  setDisposalMerchants: () => null,
  loadingDisposalMerchants: false,
  setLoadingDisposalMerchants: () => null,
  getConcurrentTourGenerationVariables: () => ({}) as ConcurrentTourParametersVariables,
  keepConcurrentGenerations: true,
  setKeepConcurrentGenerations: () => null,
  tourWeeks: [],
  vehicleDaysInput: [],
  visibleSelect: undefined,
  setVisibleSelect: () => null,
})

export const TourGenerationContextProvider: FunctionComponent<PropsWithChildren<Record<never, never>>> = (props) => {
  const [district, setDistrict] = useState<DistrictWithTowns | null>(null)
  const [towns, setTowns] = useState<DistrictTown[]>([])
  const [collectionGroup, setCollectionGroup] = useState<DistrictCollectionGroup | null>(null)
  const [firstWeek, setFirstWeek] = useState<Moment>(moment().startOf("week"))
  const [secondWeek, setSecondWeek] = useState<Moment>(firstWeek.clone().add(1, "week"))
  const [threshold, setThreshold] = useState<number | null>(80)
  const [thresholdMin, setThresholdMin] = useState<number | null>(60)
  const [vehicles, setVehicles] = useState<IVehicleEntry[]>([])
  const [materials, setMaterials] = useState<Material[]>([])
  const [version, setVersion] = useState<AlgorithmType>(AlgorithmType.TO2)
  const [maxTerminationTime, setMaxTerminationTime] = useState<Moment>(moment("12:00", "HH:mm"))
  const [maxIterations, setMaxIterations] = useState<number | null>(null)
  const [scheduleDate, setScheduleDate] = useState<Moment | null>(null)
  const [maxTerminationTimeValid, setMaxTerminationTimeValid] = useState<boolean>(false)
  const [referenceDate, setReferenceDate] = useState<Moment | null>(firstWeek.clone().add(1, "week"))
  const [referenceDateValid, setReferenceDateValid] = useState<boolean>(false)
  const [consideredTourDays, setConsideredTourDays] = useState<number | null>(0)
  const [disposalTrailerLocations, setDisposalTrailerLocations] = useState<DisposalTrailerLocation[]>([])
  const [tourWithoutContainers, setTourWithoutContainers] = useState<boolean>(false)
  const [disposalMerchants, setDisposalMerchants] = useState<AvailableDisposalMerchantsPerDay>([])
  const [loadingDisposalMerchants, setLoadingDisposalMerchants] = useState<boolean>(false)
  const [keepConcurrentGenerations, setKeepConcurrentGenerations] = useState<boolean>(true)
  const [visibleSelect, setVisibleSelect] = useState<TourGenerationSelectValue | undefined>(undefined)


  const tourDays = useMemo(() => {
    const generateWeek = (weekIndex: number) => Object.keys(day).map((d) => ({ week: weekIndex, day: d as Day }))
    return district?.allowMultipleWeeks ? [1, 2].flatMap(generateWeek) : generateWeek(1)
  }, [district])

  const tourWeeks = useMemo(
    () =>
      Object.entries(_.groupBy(tourDays, (day) => day.week)).map(([key, days]) => ({
        week: Number(key),
        weekStartDate: firstWeek
          .clone()
          .add(Number(key) - 1, "weeks")
          .toDate(),
        days,
      })),
    [tourDays, firstWeek],
  )

  useEffect(() => {
    setThresholdMin(version === AlgorithmType.AGR ? 100 : 60)
  }, [version])

  useEffect(() => {
    const isInvalid =
      maxTerminationTime === null ||
      !maxTerminationTime.isValid() ||
      (maxTerminationTime.hours() === 0 && maxTerminationTime.minutes() < 15)

    setMaxTerminationTimeValid(!isInvalid)
  }, [maxTerminationTime])

  useEffect(() => {
    if (lodash.isNil(referenceDate) || !referenceDate.isValid()) {
      setReferenceDateValid(false)
    } else {
      const isInvalid = vehicles
        .filter((vehicle) => vehicle.isActive)
        .some((vehicle) =>
          vehicle.days.some((day) =>
            firstWeek
              .clone()
              .add(dayOfWeekToNumber(day) - 1, "day")
              .isSameOrAfter(referenceDate, "day"),
          ),
        )
      setReferenceDateValid(!isInvalid)
    }
  }, [referenceDate, vehicles, firstWeek])

  const getTownIds = () => {
    if (!towns || !towns.length) {
      return []
    }

    return towns.length === district!.towns.length ? [] : towns.map((town) => town.id)
  }

  const getDisposalTrailerLocationsIds = () => {
    if (!disposalTrailerLocations?.length) {
      return []
    }

    return disposalTrailerLocations.map(
      (disposalTrailerLocation: DisposalTrailerLocation) => disposalTrailerLocation.id,
    )
  }

  const vehicleDaysInput = useMemo(
    () =>
      vehicles
        .filter((vehicle) => isVehicleActive(vehicle, version))
        .map((vehicle) => ({
          vehicle_id: vehicle.vehicleId,
          days: vehicle.days.map((day) => {
            const customDeparturePointEntry = vehicle.customDeparturePoints.find((cdpe) => isSameWeekDay(cdpe.day)(day))
            return {
              day: day.day,
              week: day.week,
              departure_point_id: customDeparturePointEntry?.departurePoint?.id || vehicle.standardDeparturePoint?.id,
              end_point_id: customDeparturePointEntry?.endPoint?.id || vehicle.standardEndPoint?.id,
            }
          }),
          operatingTimes: vehicle.customOperatingTimes.map((cot) => {
            return {
              day: cot.day.day,
              week: cot.day.week,
              minTourDuration: cot.minTourDuration,
              maxTourDuration: cot.maxTourDuration,
            }
          }),
          materials: vehicle.materials.map((material) => ({
            material_id: Number(material.material.id),
            amount: material.amount,
            is_trailer: material.is_trailer,
          })),
        })),
    [vehicles, version],
  )

  const getGenerateToursVariables = (previewCollectionPoints: TourExportPreviewCollectionPoint[]) => {
    return tourWithoutContainers
      ? getGenerateToursVariablesForTourWithoutContainers()
      : {
          district_id: district!.id,
          town_ids: getTownIds(),
          start_date: firstWeek.clone().add(firstWeek.utcOffset(), "minute"),
          week_count: secondWeek.clone().utc().diff(firstWeek.clone().utc(), "weeks"),
          threshold: Number(threshold),
          threshold_min: Number(thresholdMin),
          vehicles: vehicleDaysInput,
          preview_entries: convertCollectionPointsToPreviewEntries(previewCollectionPoints),
          material_ids: materials.map((m) => m.id),
          version,
          max_termination_time: maxTerminationTime.hour() * 3600 + maxTerminationTime.minute() * 60,
          max_iterations: maxIterations,
          schedule_date: scheduleDate || null,
          considered_tour_days: version !== AlgorithmType.AGR ? consideredTourDays : undefined,
          reference_date:
            version !== AlgorithmType.AGR ? referenceDate?.clone().add(referenceDate.utcOffset(), "minute") : undefined,
          disposal_trailer_locations_ids: getDisposalTrailerLocationsIds(),
          tour_without_containers: tourWithoutContainers,
          keep_concurrent_tours: keepConcurrentGenerations,
          collection_group_id: collectionGroup?.id ?? null,
        }
  }

  const getGenerateToursVariablesForTourWithoutContainers = (): GenerateToursVariables => {
    return {
      district_id: district!.id,
      town_ids: getTownIds(),
      start_date: firstWeek.clone().add(firstWeek.utcOffset(), "minute"),
      week_count: null,
      threshold: Number(threshold),
      threshold_min: Number(thresholdMin),
      vehicles: vehicles
        .filter((vehicle) => vehicle.isActive)
        .map((vehicle) => ({
          vehicle_id: vehicle.vehicleId,
          days: vehicle.days.map((day) => {
            const customDeparturePointEntry = vehicle.customDeparturePoints.find((cdpe) => cdpe.day === day)
            return {
              week: day.week,
              day: day.day,
              departure_point_id: customDeparturePointEntry?.departurePoint?.id || vehicle.standardDeparturePoint?.id,
              end_point_id: customDeparturePointEntry?.endPoint?.id || vehicle.standardEndPoint?.id,
            }
          }),
          operatingTimes: vehicle.days.map((day) => {
            const customOperatingTime = vehicle.customOperatingTimes.find((cote) => cote.day === day)
            return {
              week: day.week,
              day: day.day,
              minTourDuration: customOperatingTime?.minTourDuration,
              maxTourDuration: customOperatingTime?.maxTourDuration,
            }
          }),
          materials: [],
        })),
      preview_entries: [],
      material_ids: [],
      version: AlgorithmType.TO2,
      max_termination_time: maxTerminationTime.hour() * 3600 + maxTerminationTime.minute() * 60,
      max_iterations: maxIterations,
      schedule_date: scheduleDate || null,
      considered_tour_days: undefined,
      reference_date: undefined,
      disposal_trailer_locations_ids: undefined,
      tour_without_containers: true,
      keep_concurrent_tours: keepConcurrentGenerations,
      collection_group_id: collectionGroup?.id ?? null,
    }
  }

  const getConcurrentTourGenerationVariables = (): ConcurrentTourParametersVariables => {
    return {
      districtId: district!.id,
      materialIds: materials.map((m) => m.id),
      startDate: firstWeek.clone().add(firstWeek.utcOffset(), "minute"),
      townIds: getTownIds(),
      collectionGroupId: collectionGroup?.id ?? null,
      days: lodash.uniq(
        vehicles
          .filter((vehicle) => isVehicleActive(vehicle, version))
          .flatMap((vehicle) => vehicle.days.map((d) => d.day)),
      ),
    }
  }

  return (
    <TourGenerationContext.Provider
      value={{
        district,
        setDistrict,
        towns,
        setTowns,
        firstWeek,
        setFirstWeek,
        secondWeek,
        setSecondWeek,
        threshold,
        setThreshold,
        thresholdMin,
        setThresholdMin,
        vehicles,
        setVehicles,
        materials,
        setMaterials,
        version,
        setVersion,
        maxTerminationTime,
        setMaxTerminationTime,
        maxIterations,
        setMaxIterations,
        scheduleDate,
        setScheduleDate,
        maxTerminationTimeValid,
        referenceDate,
        setReferenceDate,
        referenceDateValid,
        consideredTourDays,
        setConsideredTourDays,
        disposalTrailerLocations,
        setDisposalTrailerLocations,
        tourWithoutContainers,
        setTourWithoutContainers,
        getGenerateToursVariables,
        getConcurrentTourGenerationVariables,
        disposalMerchants,
        setDisposalMerchants,
        loadingDisposalMerchants,
        setLoadingDisposalMerchants,
        keepConcurrentGenerations,
        setKeepConcurrentGenerations,
        tourWeeks,
        vehicleDaysInput,
        collectionGroup,
        setCollectionGroup,
        visibleSelect,
        setVisibleSelect
      }}
    >
      {props.children}
    </TourGenerationContext.Provider>
  )
}
