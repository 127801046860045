import React, { FC, PropsWithChildren, useCallback, useEffect, useMemo, useState } from "react"
import { useMutation, useQuery } from "@apollo/client"
import { SelectPair } from "../../../partials/layout/selection/select-pair"
import { GETMATERIALS_QUERY, MaterialsResult } from "../../../../api/graphql/queries/get-materials"
import { GET_CONTAINER_TYPES_QUERY, ContainerTypesResult } from "../../../../api/graphql/queries/get-container-types"
import {
  UPDATE_HOUSEHOLD_CONTAINER_QUERY,
  UpdatedHouseholdContainerResult,
  UpdatedHouseholdContainerVariables,
} from "../../../../api/graphql/mutations/update-household-container"
import {
  HOUSEHOLD_CONTAINER_WITH_ID_QUERY,
  HouseholdContainerWithId,
  HouseholdContainerWithIdResult,
  HouseholdContainerWithIdVariables,
} from "../../../../api/graphql/queries/household-container-with-id"
import {
  HOUSEHOLD_CONTAINER_HISTORY_QUERY,
  HouseholdContainerHistory,
  HouseholdContainerHistoryResult,
  HouseholdContainerHistoryVariables,
} from "../../../../api/graphql/queries/household-container-history"
import {
  UPDATE_HOUSEHOLD_MUTATION,
  UpdatedHouseholdResult,
  UpdatedHouseholdVariables,
} from "../../../../api/graphql/mutations/update-household"
import { toast } from "react-toastify"
import { useTranslation } from "react-i18next"

interface IHouseholdContainerContext {
  container: HouseholdContainerWithId | null
  materialOptions: SelectPair[]
  typeOptions: SelectPair[]
  formValues: IFormValues
  setFormValues: (formValues: IFormValues) => void
  updateHouseholdContainer: (input: UpdatedHouseholdContainerVariables) => void
  containerLoading: boolean
  containerError: boolean
  optionsLoading: boolean
  optionsError: boolean
  updateLoading: boolean
  handleChange: (field: keyof IFormValues, value: string) => void
  onConfirm: () => void
  histories: HouseholdContainerHistory | null
  historyLoading: boolean
  historyRefetch: () => void
  totalResults: number
  pagination: PaginationVariables
  setPagination: (pagination: PaginationVariables) => void
}

interface IHouseholdContainerContextProps {
  containerId: string
}

interface IFormValues {
  rfid: string
  material: string
  type: string
  street: string
  number: string
  postal: number
  place: string
}

interface PaginationVariables {
  page: number
  pageSize: number
}

export const HouseholdContainerContext = React.createContext<IHouseholdContainerContext>({
  container: null,
  materialOptions: [],
  typeOptions: [],
  formValues: { rfid: "", material: "", type: "", street: "", number: "", postal: 0, place: "" },
  setFormValues: () => {},
  updateHouseholdContainer: () => {},
  containerLoading: false,
  containerError: false,
  optionsLoading: false,
  optionsError: false,
  updateLoading: false,
  handleChange: () => {},
  onConfirm: () => {},
  histories: null,
  historyLoading: false,
  historyRefetch: () => {},
  totalResults: 0,
  pagination: { page: 1, pageSize: 5 },
  setPagination: () => {},
})

export const HouseholdContainerContextProvider: FC<PropsWithChildren<IHouseholdContainerContextProps>> = (props) => {
  const { containerId } = props
  const { t } = useTranslation()

  // Fetch household container data
  const {
    data: containerData,
    loading: containerLoading,
    error: containerError,
    refetch: containerRefetch,
  } = useQuery<HouseholdContainerWithIdResult, HouseholdContainerWithIdVariables>(HOUSEHOLD_CONTAINER_WITH_ID_QUERY, {
    variables: {
      id: containerId ?? "",
    },
  })

  const container = useMemo(() => containerData?.householdContainerWithId ?? null, [containerData])

  // Set default form values
  const FormDefaultValue: IFormValues = useMemo(
    () => ({
      rfid: container?.rfid ?? "",
      material: container?.material?.id ?? "",
      type: container?.type?.id ?? "",
      street: container?.household.street ?? "",
      number: container?.household.number ?? "",
      postal: container?.household.postal ?? 0,
      place: container?.household.place ?? "",
    }),
    [container],
  )

  // Set states for formValues, totalResults and pagination
  const [formValues, setFormValues] = useState<IFormValues>(FormDefaultValue)
  const [totalResults, setTotalResults] = useState<number>(0)
  const [pagination, setPagination] = useState<PaginationVariables>({ page: 1, pageSize: 5 })

  // Reset formValues when container changes
  useEffect(() => {
    setFormValues(FormDefaultValue)
  }, [FormDefaultValue])

  // Fetch household container history
  const {
    data: historyData,
    loading: historyLoading,
    refetch: historyRefetch,
  } = useQuery<HouseholdContainerHistoryResult, HouseholdContainerHistoryVariables>(HOUSEHOLD_CONTAINER_HISTORY_QUERY, {
    variables: {
      getHouseholdContainerHistoryId: container?.id ?? "",
      page: pagination.page,
      pageSize: pagination.pageSize,
    },
    onCompleted: (result) => setTotalResults(result.getHouseholdContainerHistory?.totalEntries || 0),
    onError: () => setTotalResults(0),
  })
  const histories = useMemo(() => {
    setTotalResults(historyData?.getHouseholdContainerHistory?.totalEntries || 0)
    return historyData?.getHouseholdContainerHistory || null
  }, [historyData])

  // Fetch materials
  const {
    data: materialsData,
    loading: materialsLoading,
    error: materialsError,
  } = useQuery<MaterialsResult>(GETMATERIALS_QUERY)

  const materials = useMemo(() => materialsData?.getMaterials || [], [materialsData])
  const materialOptions = useMemo(
    () => materials.map((material) => new SelectPair(material.id, material.name)),
    [materials],
  )

  // Fetch container types
  const {
    data: typesData,
    loading: typesLoading,
    error: typesError,
  } = useQuery<ContainerTypesResult>(GET_CONTAINER_TYPES_QUERY)

  const types = useMemo(() => typesData?.getContainerTypes || [], [typesData])
  const typeOptions = useMemo(() => types.map((type) => new SelectPair(type.id, type.name)), [types])

  // Update household
  const [updateHouseholdMutation, { loading: updateHouseholdLoading }] = useMutation<UpdatedHouseholdResult>(
    UPDATE_HOUSEHOLD_MUTATION,
    { onError: () => toast.error(t("household_details.edit_error")) },
  )

  const updateHousehold = async (input: UpdatedHouseholdVariables) => {
    const result = await updateHouseholdMutation({ variables: input })
    if (result.data?.updateHousehold) {
      containerRefetch()
    }
  }

  // Update household container
  const [updateHouseholdContainerMutation, { loading: updateHouseholdContainerLoading }] =
    useMutation<UpdatedHouseholdContainerResult>(UPDATE_HOUSEHOLD_CONTAINER_QUERY, {
      onError: () => toast.error(t("household_details.edit_error")),
    })

  const updateHouseholdContainer = async (input: UpdatedHouseholdContainerVariables) => {
    const result = await updateHouseholdContainerMutation({ variables: input })
    if (result.data?.updateHouseholdContainer) {
      containerRefetch()
    }
  }

  // Handle form changes
  const handleChange = useCallback((field: keyof IFormValues, value: string) => {
    setFormValues((prev) => ({ ...prev, [field]: value }))
  }, [])

  // Handle confirm edit
  const onConfirmEdit = useCallback(async () => {
    if (container?.id === undefined) return
    const updatedContainer = {
      updateHouseholdContainerId: container?.id ?? "",
      rfid: formValues?.rfid,
      materialId: formValues?.material,
      containerTypeId: formValues?.type,
    }
    await updateHouseholdContainer(updatedContainer)
    const updatedHousehold = {
      updateHouseholdId: container?.household?.id ?? "",
      street: formValues?.street,
      number: formValues?.number,
      postal: Number(formValues?.postal),
      place: formValues?.place,
    }
    const hasHouseholdChanged =
      updatedHousehold.street !== FormDefaultValue.street ||
      updatedHousehold.number !== FormDefaultValue.number ||
      updatedHousehold.postal !== FormDefaultValue.postal ||
      updatedHousehold.place !== FormDefaultValue.place
    if (hasHouseholdChanged) {
      await updateHousehold(updatedHousehold)
    }
    historyRefetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues, updateHouseholdContainer])

  return (
    <HouseholdContainerContext.Provider
      value={{
        container,
        materialOptions,
        typeOptions,
        formValues,
        setFormValues,
        updateHouseholdContainer,
        containerLoading,
        containerError: !!containerError,
        optionsLoading: materialsLoading || typesLoading,
        optionsError: !!(materialsError || typesError),
        updateLoading: updateHouseholdLoading || updateHouseholdContainerLoading,
        handleChange,
        onConfirm: onConfirmEdit,
        histories,
        historyLoading,
        historyRefetch,
        totalResults,
        pagination,
        setPagination,
      }}
    >
      {props.children}
    </HouseholdContainerContext.Provider>
  )
}
