import React, { useEffect, useState } from "react"
import { HouseholdContainerMarker } from "./household-container-marker"
import { GoogleMap, MarkerClusterer } from "@react-google-maps/api"
import { Box } from "@mui/material"
import { HouseholdForHouseholdContainerWithId } from "../../../../api/graphql/queries/household-container-with-id"

interface IHouseholdContainerMapProps {
  household: HouseholdForHouseholdContainerWithId | undefined
}

const MAP_HEIGHT = 300

export const HouseholdContainerMap: React.FC<IHouseholdContainerMapProps> = (props) => {
  const household = props.household
  const [map, setMap] = useState<google.maps.Map>()

  const center = { lat: household?.latitude ?? 0, lng: household?.longitude ?? 0 }

  useEffect(() => {
    if (!map || !household) {
      return
    }

    const bounds = new window.google.maps.LatLngBounds()

    bounds.extend({
      lat: household.latitude,
      lng: household.longitude,
    })

    map.fitBounds(bounds)
  }, [map, household])

  return (
    <GoogleMap
      center={center}
      onLoad={setMap}
      mapContainerStyle={{
        width: "100%",
        height: MAP_HEIGHT,
        position: "relative",
        borderRadius: 4,
      }}
    >
      {household && (
        <MarkerClusterer maxZoom={20}>
          {(clusterer) => (
            <Box>
              <HouseholdContainerMarker key={0} household={household} clusterer={clusterer} />
            </Box>
          )}
        </MarkerClusterer>
      )}
    </GoogleMap>
  )
}
