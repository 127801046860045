import { gql } from "@apollo/client"
import {
  getCollectionPointsWithRegionID,
  getCollectionPointsWithRegionIDVariables,
  getCollectionPointsWithRegionID_getCollectionPointsWithRegionID,
  getCollectionPointsWithRegionID_getCollectionPointsWithRegionID_entries,
} from "./types/getCollectionPointsWithRegionID"

export const GET_COLLECTIONPOINT_WITH_REGION_ID_QUERY = gql`
  query getCollectionPointsWithRegionID(
    $id: Int!
    $type: RegionType!
    $page: Int!
    $pagesize: Int!
    $order: CollectionPointOrderInput
    $filter: RegionFilterInput
    $date: DateTime
  ) {
    getCollectionPointsWithRegionID(
      id: $id
      type: $type
      page: $page
      pagesize: $pagesize
      order: $order
      filter: $filter
    ) {
      totalPages
      totalEntries
      page
      pageSize
      entries {
        id
        description
        street
        latitude
        longitude
        location_number
        cadastral_name
        cadastral_nr
        postal
        place
        town {
          id
          name
        }
        next_emptying
        last_emptying
        collectionPointFilllevels(sorted: true, date: $date) {
          count_containers
          filllevel
          material_id
        }
        containers(sorted: true) {
          id
          last_time_send
          material_id
          lid_open
          lid_changed
          sensor_id
          defectiveSensor
          containerWarnings {
            warning_type
            since
          }
        }
        fixed_unload_interval
        collection_groups {
          id
          label
          materials {
            id
          }
        }
      }
    }
  }
`

export type GetCollectionPointsWithRegionIDResult = getCollectionPointsWithRegionID
export type GetCollectionPointsWithRegionIDVariables = getCollectionPointsWithRegionIDVariables
export type GetCollectionPointsWithRegionIdPaginationResult = getCollectionPointsWithRegionID_getCollectionPointsWithRegionID
export type CollectionPoint = getCollectionPointsWithRegionID_getCollectionPointsWithRegionID_entries
