import { Popover, Stack, Typography } from "@mui/material"
import React from "react"
import { CollectionPointCollectionGroup } from "./collection-group-collection-points-table"

interface Props {
  anchorEl: Element | undefined
  setAnchorEl: (value: Element | undefined) => void
  collectionGroups: CollectionPointCollectionGroup[]
}

export const CollectionGroupDetailPopover: React.FC<Props> = (props) => {
  const { anchorEl, setAnchorEl, collectionGroups } = props

  return (
    <Popover
      id="collection-group-detail-popover"
      open={!!anchorEl}
      onClose={() => setAnchorEl(undefined)}
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: "right",
        vertical: "top",
      }}
    >
      <Stack direction="column" spacing={2} p={2}>
        {collectionGroups.map((collectionGroup) => {
          return <Typography>{collectionGroup.label}</Typography>
        })}
      </Stack>
    </Popover>
  )
}
