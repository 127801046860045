import { UserRole } from "../api/graphql/graphql-global-types"

export const UserGroups = {
  USER_MANAGEMENT: [UserRole.SUPER_ADMIN, UserRole.TOWN_ADMIN],
  USER_MANAGEMENT_ACCESS_ROLE: {
    [UserRole.ASSOCIATION]: [UserRole.SUPER_ADMIN, UserRole.TOWN_ADMIN],
    [UserRole.COLLECTION_PARTNER]: [UserRole.SUPER_ADMIN, UserRole.TOWN_ADMIN],
    [UserRole.DRIVER]: [UserRole.SUPER_ADMIN],
    [UserRole.SUPER_ADMIN]: [UserRole.SUPER_ADMIN],
    [UserRole.TOWN_ADMIN]: [UserRole.SUPER_ADMIN],
    [UserRole.GUIDANCE_ADMIN]: [UserRole.SUPER_ADMIN],
    [UserRole.GUIDED_DRIVER]: [UserRole.SUPER_ADMIN],
    [UserRole.RFID_APP]: [UserRole.SUPER_ADMIN],
  },
  COLLECTION_POINT_MANAGEMENT: [UserRole.SUPER_ADMIN, UserRole.COLLECTION_PARTNER],
  PORTAL_ACCESS: [
    UserRole.ASSOCIATION,
    UserRole.SUPER_ADMIN,
    UserRole.TOWN_ADMIN,
    UserRole.COLLECTION_PARTNER,
    UserRole.GUIDANCE_ADMIN,
  ],
  PARTNER_MANAGEMENT: [UserRole.TOWN_ADMIN, UserRole.COLLECTION_PARTNER, UserRole.SUPER_ADMIN],
  PARTNER_SELECTOR: [UserRole.TOWN_ADMIN, UserRole.SUPER_ADMIN],
  VEHICLE_MANAGEMENT: [UserRole.SUPER_ADMIN, UserRole.COLLECTION_PARTNER],
  DISPOSAL_MERCHANT_MANAGEMENT: [UserRole.COLLECTION_PARTNER],
  ASSOCIATION_SELECT: [UserRole.ASSOCIATION, UserRole.TOWN_ADMIN], // !! if changed, also needs to be changed in API !!
  DISTRICT_SELECT: [UserRole.COLLECTION_PARTNER, UserRole.SUPER_ADMIN], // !! if changed, also needs to be changed in API !!
  DASHBOARD: [UserRole.ASSOCIATION, UserRole.SUPER_ADMIN, UserRole.TOWN_ADMIN, UserRole.COLLECTION_PARTNER],
  COLLECTION_POINTS: [UserRole.ASSOCIATION, UserRole.SUPER_ADMIN, UserRole.TOWN_ADMIN, UserRole.COLLECTION_PARTNER],
  TOURS: [UserRole.ASSOCIATION, UserRole.SUPER_ADMIN, UserRole.TOWN_ADMIN, UserRole.COLLECTION_PARTNER],
  TOUR_MANAGEMENT: [UserRole.SUPER_ADMIN, UserRole.COLLECTION_PARTNER],
  ROUTES: [UserRole.GUIDANCE_ADMIN],
  CONTAINER_WARNINGS_MANAGEMENT: [UserRole.SUPER_ADMIN, UserRole.COLLECTION_PARTNER],
  HOUSEHOLD_OVERVIEW: [UserRole.SUPER_ADMIN, UserRole.ASSOCIATION, UserRole.COLLECTION_PARTNER, UserRole.TOWN_ADMIN], // !! if changed, also needs to be changed in API !!
  COLLECTION_GROUP_MANAGEMENT: [UserRole.SUPER_ADMIN, UserRole.COLLECTION_PARTNER], // !! if changed, also needs to be changed in API !!
}
