import { AddOutlined, DeleteOutline } from "@mui/icons-material"
import { Grid, Stack, TextField, Typography } from "@mui/material"
import React, { useMemo, useState } from "react"
import { Material } from "../../../../api/graphql/queries/get-materials"
import { useTranslation } from "react-i18next"
import { DeleteDialog } from "../../../partials/layout/dialog/delete-dialog"
import { ModeOption } from "../context/collection-group-wizard-context"

interface Props {
  materials: Material[]
  selectedMaterialIds: number[]
  setSelectedMaterialIds: (ids: number[]) => void
  mode: ModeOption
}

export const MaterialInput: React.FC<Props> = (props) => {
  const { materials, selectedMaterialIds, setSelectedMaterialIds, mode } = props
  const { t } = useTranslation()

  const { displayPleaseSelectHint, displayAllSelectedHint } = useMemo(() => {
    const displayPleaseSelectHint = materials.length > 0 && selectedMaterialIds.length === 0
    const displayAllSelectedHint =
      materials.length > 0 && materials.map((m) => m.id).every((id) => selectedMaterialIds.includes(Number(id)))

    return { displayPleaseSelectHint, displayAllSelectedHint }
  }, [materials, selectedMaterialIds])

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [materialIdToDelete, setMaterialIdToDelete] = useState<number | undefined>(undefined)

  const onDelete = (id: number | undefined) => {
    if (id !== undefined) {
      setSelectedMaterialIds(selectedMaterialIds.filter((mId) => mId !== id))
    }
  }

  const onDeleteClick = (id: number) => {
    if (mode === "edit") {
      setMaterialIdToDelete(id)
      setDeleteDialogOpen(true)
      return
    }
    onDelete(id)
  }

  const onDeleteConfirmed = async () => {
    onDelete(materialIdToDelete)
    setDeleteDialogOpen(false)
    setMaterialIdToDelete(undefined)
  }

  const getMaterialName = (id: number | undefined) => {
    const material = materials.find((m) => Number(m.id) === id)
    return material?.name ?? ""
  }

  return (
    <Stack direction="column">
      <DeleteDialog
        open={deleteDialogOpen}
        onClose={() => {
          setDeleteDialogOpen(false)
          setMaterialIdToDelete(undefined)
        }}
        content={t("collection_groups.mutate_page.confirm_delete_material", {
          material: getMaterialName(materialIdToDelete),
        })}
        onDelete={onDeleteConfirmed}
      />
      <Stack direction="column" spacing={2} pb={3}>
        {displayPleaseSelectHint && (
          <Typography>{t("collection_groups.mutate_page.please_select_materials")}</Typography>
        )}
        {selectedMaterialIds.map((id, index) => {
          const material = materials.find((m) => Number(m.id) === id)
          return (
            <Stack key={id} direction="row" spacing={2} alignItems="center">
              <Typography sx={{ width: 16 }}>{index + 1}</Typography>
              <TextField fullWidth variant="outlined" value={material?.name ?? ""} />
              <DeleteOutline onClick={() => onDeleteClick(id)} sx={{ "&:hover": { cursor: "pointer" } }} />
            </Stack>
          )
        })}
      </Stack>

      {materials.length > 0 && (
        <>
          <Typography pb={1} fontWeight={700}>
            {t("collection_groups.mutate_page.materials_selection")}
          </Typography>
          <Grid container direction="row" spacing={2}>
            {materials
              .filter((m) => !selectedMaterialIds.includes(Number(m.id)))
              .map((material) => (
                <Grid item key={material.id}>
                  <Stack
                    direction="row"
                    sx={{
                      borderRadius: 4,
                      p: 0.5,
                      "&:hover": { cursor: "pointer" },
                      backgroundColor: "#EBF5FE",
                    }}
                    onClick={() => setSelectedMaterialIds([...selectedMaterialIds, Number(material.id)])}
                    alignItems="center"
                  >
                    <AddOutlined fontSize="small" sx={{ color: "#2F90F9" }} />
                    <Typography color="#2F90F9" pr={1}>
                      {material.name}
                    </Typography>
                  </Stack>
                </Grid>
              ))}
          </Grid>
          {displayAllSelectedHint && <Typography mt={2}>{t("collection_groups.mutate_page.all_selected")}</Typography>}
        </>
      )}
      {materials.length === 0 && <Typography>{t("collection_groups.mutate_page.no_materials")}</Typography>}
    </Stack>
  )
}
