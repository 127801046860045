import React, { useContext } from "react"
import { Grid, Typography } from "@mui/material"
import { HouseholdForHouseholdContainerWithId } from "../../../../api/graphql/queries/household-container-with-id"
import { HouseholdContainerContext } from "../context/household-container-context"

interface IHouseholdMarkerInfoWindowContentProps {
  household: HouseholdForHouseholdContainerWithId
}

export const HouseholdContainerMarkerInfoContent: React.FC<IHouseholdMarkerInfoWindowContentProps> = (props) => {
  const { household } = props
  const { container } = useContext(HouseholdContainerContext)

  return (
    <Grid container direction="column" sx={{ p: 1 }}>
      <Grid item>
        <Typography
          sx={{ fontWeight: 700 }}
        >{`${household.street} ${household.number}, ${household.postal} ${household.place}`}</Typography>
      </Grid>
      <Grid
        item
        key={`${household.id}-${container?.material?.id}`}
        container
        alignItems="center"
        spacing={1}
        wrap="nowrap"
      >
        <Grid item container direction="column">
          <Typography sx={{ fontWeight: 400 }}>{`${container?.material?.name} - ${container?.type?.name}`}</Typography>
          <Typography sx={{ fontWeight: 400 }}>{container?.rfid}</Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}
