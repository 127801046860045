/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * Algorithm for tour parameterization
 */
export enum AlgorithmType {
  AGR = "AGR",
  TO2 = "TO2",
  TO2TRAILER = "TO2TRAILER",
}

export enum CollectionPointSortingAttribute {
  COLLECTION_GROUP_COUNT = "COLLECTION_GROUP_COUNT",
  FILLLEVEL = "FILLLEVEL",
  ID = "ID",
  LAST_EMTPYING = "LAST_EMTPYING",
  NEXT_EMPTYING = "NEXT_EMPTYING",
}

/**
 * Type of collection (On Demand / On Interval / On Sensor) from a collection point
 */
export enum CollectionType {
  ON_DEMAND = "ON_DEMAND",
  ON_INTERVAL = "ON_INTERVAL",
  ON_SENSOR = "ON_SENSOR",
}

export enum ContainerNotificationType {
  CRITICAL_BATTERY = "CRITICAL_BATTERY",
  CRITICAL_FILLLEVEL_CHANGE = "CRITICAL_FILLLEVEL_CHANGE",
  FULL = "FULL",
  OFFLINE = "OFFLINE",
}

/**
 * Status of container warning
 */
export enum ContainerWarningStatus {
  DONE = "DONE",
  OPEN = "OPEN",
  PROGRESS = "PROGRESS",
}

/**
 * Type of container warning
 */
export enum ContainerWarningType {
  COLLECTION_POINT_SWITCHED_TO_DRIVER_FEEDBACK = "COLLECTION_POINT_SWITCHED_TO_DRIVER_FEEDBACK",
  DEFECTIVE_SENSOR = "DEFECTIVE_SENSOR",
  LID_OPEN = "LID_OPEN",
  NOT_ENOUGH_FEEDBACK_DATA = "NOT_ENOUGH_FEEDBACK_DATA",
  NO_RECENT_SENSOR_DATA = "NO_RECENT_SENSOR_DATA",
}

/**
 * Weekday
 */
export enum Day {
  friday = "friday",
  monday = "monday",
  saturday = "saturday",
  sunday = "sunday",
  thursday = "thursday",
  tuesday = "tuesday",
  wednesday = "wednesday",
}

/**
 * Status of database cloning.
 */
export enum DbCloningStatus {
  BackupImportError = "BackupImportError",
  DatabasesIncompatibleError = "DatabasesIncompatibleError",
  DatabasesVersionFetchError = "DatabasesVersionFetchError",
  DeleteTourgenerationsFailedWarning = "DeleteTourgenerationsFailedWarning",
  DummyTourImportFailedWarning = "DummyTourImportFailedWarning",
  Error = "Error",
  ExportError = "ExportError",
  ImportError = "ImportError",
  MigrationFailedWarning = "MigrationFailedWarning",
  ResetError = "ResetError",
  Running = "Running",
  ScheduledToursLeftWarning = "ScheduledToursLeftWarning",
  Success = "Success",
}

/**
 * Type of Document Template
 */
export enum DocumentTemplateType {
  COLLECTION_POINT_BULK_UPLOAD = "COLLECTION_POINT_BULK_UPLOAD",
}

export enum EmissionStandard {
  euro5 = "euro5",
  euro5eev = "euro5eev",
  euro6 = "euro6",
  euro7 = "euro7",
}

/**
 * Emptying Type
 */
export enum EmptyingType {
  LIFTING = "LIFTING",
  LIFTING_OEKOBELL = "LIFTING_OEKOBELL",
  LIFTING_OEKOLINE = "LIFTING_OEKOLINE",
  POUR = "POUR",
  UF = "UF",
}

export enum EventType {
  BACKWARDS = "BACKWARDS",
  BULK = "BULK",
}

/**
 * Reason for an exported collectionpoint
 */
export enum ExportReason {
  EMPTYING_REQUESTED = "EMPTYING_REQUESTED",
  HIGH_FILLLEVEL = "HIGH_FILLLEVEL",
  INTERVAL_DUE = "INTERVAL_DUE",
  MANUALLY_INSERTED = "MANUALLY_INSERTED",
  MANUALLY_INSERTED_BY_DRIVER = "MANUALLY_INSERTED_BY_DRIVER",
  OTHER = "OTHER",
}

/**
 * Status of File Export
 */
export enum FileExportStatus {
  CANCELLED = "CANCELLED",
  DOWNLOADED = "DOWNLOADED",
  ERROR = "ERROR",
  PROCESSING = "PROCESSING",
  REQUESTED = "REQUESTED",
  SUCCESS = "SUCCESS",
}

/**
 * Comment for household containers
 */
export enum HouseholdContainerCommentType {
  CONTAINER_DEFECTIVE = "CONTAINER_DEFECTIVE",
  CONTAINER_NOT_CHIPABLE = "CONTAINER_NOT_CHIPABLE",
  CONTAINER_NOT_PROVIDED = "CONTAINER_NOT_PROVIDED",
  CONTAINER_OLD = "CONTAINER_OLD",
}

/**
 * sorting attribute for household containers query
 */
export enum HouseholdContainerSortingAttribute {
  ADDRESS = "ADDRESS",
  COMMENT = "COMMENT",
  ID = "ID",
}

/**
 * used to display current status of intended container / actual container ratio of household
 */
export enum HouseholdStatusColor {
  GREEN = "GREEN",
  GREY = "GREY",
  RED = "RED",
}

export enum Language {
  de = "de",
  en = "en",
}

/**
 * Expexted order by values
 */
export enum OrderBy {
  ASC = "ASC",
  DESC = "DESC",
}

/**
 * Region Type
 */
export enum RegionType {
  ASSOCIATION = "ASSOCIATION",
  DISTRICT = "DISTRICT",
}

export enum STOP_TYPE {
  COLLECTION_POINT = "COLLECTION_POINT",
  DEPARTURE_POINT = "DEPARTURE_POINT",
  DISPOSAL_MERCHANT = "DISPOSAL_MERCHANT",
  TRAILER_LOCATION = "TRAILER_LOCATION",
}

/**
 * Stop Status
 */
export enum StopStatus {
  AT_STOP = "AT_STOP",
  CANCELED = "CANCELED",
  FINISHED = "FINISHED",
  NONE = "NONE",
  STARTED = "STARTED",
}

/**
 * Error Type for collection point upload validation
 */
export enum UploadValidationErrorType {
  connected_region_not_found = "connected_region_not_found",
  duplicate_header = "duplicate_header",
  invalid_accessible_times = "invalid_accessible_times",
  invalid_container_type_info = "invalid_container_type_info",
  invalid_container_types = "invalid_container_types",
  invalid_coordinates = "invalid_coordinates",
  invalid_discharge_types = "invalid_discharge_types",
  invalid_materials = "invalid_materials",
  missing_header = "missing_header",
  missing_header_row = "missing_header_row",
  missing_required_fields = "missing_required_fields",
}

/**
 * Role of the User
 */
export enum UserRole {
  ASSOCIATION = "ASSOCIATION",
  COLLECTION_PARTNER = "COLLECTION_PARTNER",
  DRIVER = "DRIVER",
  GUIDANCE_ADMIN = "GUIDANCE_ADMIN",
  GUIDED_DRIVER = "GUIDED_DRIVER",
  RFID_APP = "RFID_APP",
  SUPER_ADMIN = "SUPER_ADMIN",
  TOWN_ADMIN = "TOWN_ADMIN",
}

export interface CollectionGroupInput {
  district_id: number;
  label: string;
  material_ids: number[];
  collection_point_ids: number[];
}

export interface CollectionPointOrderInput {
  orderBy?: CollectionPointSortingAttribute | null;
  sortingType?: OrderBy | null;
  materialIdToSortBy?: string | null;
}

export interface ContainerInput {
  id?: string | null;
  material_id?: number | null;
  number?: string | null;
  type_id?: number | null;
  alternative_type?: string | null;
  manufacturer_id?: number | null;
  alternative_manufacturer?: string | null;
  sensor_id?: string | null;
  collection_point_id?: number | null;
  emptying_requested?: boolean | null;
}

export interface DayWithCustomDeparturePoint {
  departure_point_id?: number | null;
  end_point_id?: number | null;
  week: number;
  day: Day;
}

export interface DayWithCustomVehicleOperatingTimes {
  maxTourDuration?: number | null;
  minTourDuration?: number | null;
  week: number;
  day: Day;
}

export interface DeparturePointInput {
  name: string;
  latitude: number;
  longitude: number;
  collectionPartnerId: string;
}

export interface DisposalMerchantVacationTimesInput {
  from: any;
  to: any;
}

export interface DisposalTrailerLocationInput {
  name: string;
  latitude: number;
  longitude: number;
  district_id: string;
  collection_partner_id: string;
}

export interface DisposalTrailerLocationUpdateInput {
  name?: string | null;
  latitude?: number | null;
  longitude?: number | null;
  district_id?: string | null;
  collection_partner_id?: string | null;
}

export interface ExtrapolationWeightingsInput {
  monday?: number | null;
  tuesday?: number | null;
  wednesday?: number | null;
  thursday?: number | null;
  friday?: number | null;
  saturday?: number | null;
  sunday?: number | null;
}

export interface HouseholdOrderInput {
  orderBy?: HouseholdContainerSortingAttribute | null;
  sortingType?: OrderBy | null;
}

export interface MaterialInput {
  id: number;
  name: string;
}

export interface OpeningHourInput {
  day: string;
  from: string;
  to: string;
  active?: boolean | null;
}

export interface RegionFilterInput {
  description?: string | null;
  postal?: string | null;
  searchQuery?: string | null;
  containerNumber?: string | null;
  towns?: string[] | null;
  address?: string | null;
  onlyIssueContainers?: boolean | null;
  minFilllevel?: number | null;
  materialIds?: string[] | null;
  sensorId?: string | null;
  collectionTypes?: CollectionType[] | null;
  counties?: string[] | null;
}

export interface RouteInput {
  file: any;
  tourName: string;
}

export interface StopFilllevelInput {
  material_id: string;
  filllevel: number;
}

export interface StopInput {
  stop_id?: string | null;
  type: STOP_TYPE;
  id: string;
  sequence_number: number;
  filllevels: StopFilllevelInput[];
}

export interface TourExportPreviewCollectionPointInput {
  id: string;
  location_number?: string | null;
  description: string;
  street?: string | null;
  place?: string | null;
  fixed_unload_interval?: number | null;
  postal?: number | null;
  town: string;
  materials: TourExportPreviewCollectionPointMaterialInput[];
  last_emptying?: any | null;
}

export interface TourExportPreviewCollectionPointMaterialInput {
  material: MaterialInput;
  filllevel?: number | null;
  filllevel_percentage?: number | null;
  max_amount?: number | null;
}

export interface TourExportPreviewInput {
  districtName: string;
  collectionPoints: TourExportPreviewCollectionPointInput[];
  materials: TourExportPreviewMaterialInput[];
}

export interface TourExportPreviewMaterialInput {
  material: MaterialInput;
  filllevel: number;
}

export interface TourPreviewInput {
  collection_point_id: string;
  material_id: string;
  filllevel?: number | null;
  max_amount?: number | null;
  last_emptying?: any | null;
}

export interface UploadCollectionPointInput {
  collectionPointId?: string | null;
  locationNumber?: string | null;
  description?: string | null;
  street?: string | null;
  postalCode?: number | null;
  town: string;
  cadastralTown?: string | null;
  cadastralNumber?: number | null;
  longitude: number;
  latitude: number;
  accessibleFrom: string;
  accessibleTo: string;
  fixedUnloadInterval?: number | null;
  accessibleWithTrailer: boolean;
  containers: UploadContainerInput[];
}

export interface UploadContainerInput {
  number?: string | null;
  sensorId?: string | null;
  materialId: string;
  typeId: string;
}

export interface VehicleDaysInput {
  vehicle_id: number;
  days: DayWithCustomDeparturePoint[];
  operatingTimes: DayWithCustomVehicleOperatingTimes[];
  materials: VehicleToMaterialInput[];
}

export interface VehicleOperatingTimesInput {
  day: Day;
  max_tour_duration: number;
  min_tour_duration: number;
  active?: boolean | null;
}

export interface VehicleToMaterialInput {
  material_id: number;
  amount: number;
  is_trailer: boolean;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
