/*
    Hook which provides all collection points filter functionalities
*/

import React, { useState, useContext, createContext, useEffect } from "react"
import {
  CollectionPointOrderInput,
  CollectionPointSortingAttribute,
  OrderBy,
  RegionFilterInput,
} from "../api/graphql/graphql-global-types"
import moment, { Moment } from "moment"

export type ICollectionPointsFilter = Required<RegionFilterInput>
export type ICollectionPointsOrder = Required<CollectionPointOrderInput>

export const defaultFilterValues: ICollectionPointsFilter = {
  description: "",
  postal: "",
  containerNumber: "",
  sensorId: "",
  address: "",
  towns: [],
  onlyIssueContainers: false,
  minFilllevel: -1,
  materialIds: [],
  collectionTypes: [],
  counties: [],
  searchQuery: null
}

export const defaultTablePropsValues: ICollectionPointTableProps = {
  page: 0,
  pagesize: 10,
  order: {
    orderBy: CollectionPointSortingAttribute.ID,
    sortingType: OrderBy.ASC,
    materialIdToSortBy: null,
  },
}

export interface ICollectionPointTableProps {
  page: number
  pagesize: number
  order: ICollectionPointsOrder
}

interface ICollectionPointsFilterContextType {
  scrollPosition: number
  setScrollPosition: (scrollPosition: number) => void
  filter: ICollectionPointsFilter
  isFilterSet: boolean
  setFilter: (value: ICollectionPointsFilter) => void
  resetFilter: () => void
  referenceDate: Moment
  setReferenceDate: (date: Moment) => void
  tableProps: ICollectionPointTableProps
  setTableProps: (value: ICollectionPointTableProps) => void
  resetTableProps: () => void
}

export const CollectionPointsFilterContext = createContext<ICollectionPointsFilterContextType>(
  {} as ICollectionPointsFilterContextType,
)

export const CollectionPointsFilterProvider = (props: any) => {
  const auth = useCollectionPointsFilterProvider()

  return <CollectionPointsFilterContext.Provider value={auth}>{props.children}</CollectionPointsFilterContext.Provider>
}

export const useCollectionPointsFilter = () => {
  return useContext(CollectionPointsFilterContext)
}

const useCollectionPointsFilterProvider = (): ICollectionPointsFilterContextType => {
  const [filter, setFilter] = useState<ICollectionPointsFilter>(defaultFilterValues)
  const [isFilterSet, setIsFilterSet] = useState<boolean>(false)
  const [tableProps, setTableProps] = useState<ICollectionPointTableProps>(defaultTablePropsValues)
  const [scrollPosition, setScrollPosition] = useState<number>(0)
  const [referenceDate, setReferenceDate] = useState<Moment>(moment())

  useEffect(() => {
    const isSet = Object.entries(filter).some(
      ([key, value]) => value !== defaultFilterValues[key as keyof ICollectionPointsFilter],
    )
    setIsFilterSet(isSet)
  }, [filter])

  const resetFilter = () => {
    setFilter(defaultFilterValues)
  }

  const resetTableProps = () => {
    setTableProps(defaultTablePropsValues)
  }

  return {
    scrollPosition,
    setScrollPosition,
    filter,
    isFilterSet,
    setFilter,
    resetFilter,
    tableProps,
    setTableProps,
    resetTableProps,
    referenceDate,
    setReferenceDate,
  }
}
