import { gql } from "@apollo/client"
import { generateTours, generateToursVariables } from "./types/generateTours"

export const GENERATE_TOURS_MUTATION = gql`
  mutation generateTours(
    $district_id: ID!
    $town_ids: [ID!]!
    $start_date: DateTime!
    $week_count: Int
    $threshold: Int!
    $threshold_min: Int!
    $vehicles: [VehicleDaysInput!]!
    $preview_entries: [TourPreviewInput!]!
    $material_ids: [ID!]!
    $version: AlgorithmType!
    $max_termination_time: Int!
    $max_iterations: Int
    $schedule_date: DateTime
    $considered_tour_days: Int
    $reference_date: DateTime
    $disposal_trailer_locations_ids: [ID!]
    $tour_without_containers: Boolean!
    $keep_concurrent_tours: Boolean!
    $collection_group_id: ID
  ) {
    generateTours(
      district_id: $district_id
      town_ids: $town_ids
      start_date: $start_date
      week_count: $week_count
      threshold: $threshold
      threshold_min: $threshold_min
      vehicles: $vehicles
      preview_entries: $preview_entries
      material_ids: $material_ids
      version: $version
      max_termination_time: $max_termination_time
      max_iterations: $max_iterations
      schedule_date: $schedule_date
      considered_tour_days: $considered_tour_days
      reference_date: $reference_date
      disposal_trailer_locations_ids: $disposal_trailer_locations_ids
      tour_without_containers: $tour_without_containers
      keep_concurrent_tours: $keep_concurrent_tours
      collection_group_id: $collection_group_id
    ) {
      error
      message
    }
  }
`

export type GenerateToursResult = generateTours
export type GenerateToursVariables = generateToursVariables
