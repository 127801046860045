import { gql } from "@apollo/client"
import {
  getHouseholdDischargesOfTourPaginated,
  getHouseholdDischargesOfTourPaginated_getHouseholdDischargesOfTourPaginated,
  getHouseholdDischargesOfTourPaginated_getHouseholdDischargesOfTourPaginated_householdContainer,
  getHouseholdDischargesOfTourPaginated_getHouseholdDischargesOfTourPaginated_householdContainer_household,
  getHouseholdDischargesOfTourPaginatedVariables,
} from "./types/getHouseholdDischargesOfTourPaginated"

export const GET_HOUSEHOLD_DISCHARGES_OF_TOUR_PAGINATED_QUERY = gql`
  query getHouseholdDischargesOfTourPaginated($tourId: Int!, $page: Int, $pageSize: Int, $warningFilter: Boolean!) {
    getHouseholdDischargesOfTourPaginated(
      tourId: $tourId
      page: $page
      pageSize: $pageSize
      warningFilter: $warningFilter
    ) {
      id
      householdTourId
      sequenceNumber
      date
      rfidHex
      weight
      warning
      householdContainer {
        household {
          place
          postal
          street
          number
        }
        id
        rfid
        type {
          size
        }
      }
    }
  }
`

export type HouseholdDischargesOfTourPaginatedResult = getHouseholdDischargesOfTourPaginated
export type HouseholdDischargesOfTourPaginatedVariables = getHouseholdDischargesOfTourPaginatedVariables
export type HouseholdDischargesOfTourPaginated =
  getHouseholdDischargesOfTourPaginated_getHouseholdDischargesOfTourPaginated
export type HouseholdDischargesOfTourPaginatedHouseholdContainer =
  getHouseholdDischargesOfTourPaginated_getHouseholdDischargesOfTourPaginated_householdContainer

export type HouseholdDischargesOfTourHousehold = getHouseholdDischargesOfTourPaginated_getHouseholdDischargesOfTourPaginated_householdContainer_household