import React, { useContext, useState } from "react"
import { Button, Grid, Stack, Typography } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { PATH } from "../../../../router/router"
import { ArrowBack } from "@mui/icons-material"
import { HouseholdContainerContext } from "../context/household-container-context"
import { HouseholdContainerDeleteConfirmDialog } from "../../households/partials/household-container-delete-confirm-dialog"

interface IHouseholdContainerHeaderProps {}

export const HouseholdContainerHeader: React.FC<IHouseholdContainerHeaderProps> = (props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { container } = useContext(HouseholdContainerContext)
  const [open, setOpen] = useState<boolean>(false)
  return (
    <>
      <HouseholdContainerDeleteConfirmDialog open={open} onClose={() => setOpen(false)} />
      <Grid display="flex" justifyContent="flex-start">
        <Button variant="outlined" onClick={() => navigate(PATH.HOUSEHOLD_OVERVIEW.route)} startIcon={<ArrowBack />}>
          {t("household_details.back_to_overview")}
        </Button>
      </Grid>
      <Grid item display="flex" justifyContent="space-between" alignItems="center" sx={{ py: 1 }}>
        <Stack direction="column">
          <Typography variant="body2" sx={{ fontWeight: 700 }}>
            {t("household_details.support_id")}
          </Typography>
          <Typography variant="body2">{`#${container?.id ?? ""}`}</Typography>
        </Stack>
        <Button variant="contained" onClick={() => setOpen(true)}>
          {t("household_details.delete_container")}
        </Button>
      </Grid>
    </>
  )
}
