import { Box, Stack, TextField } from "@mui/material"
import React, { useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { CustomAutocomplete } from "../../../partials/customselect/custom-select"
import { SelectOption } from "../../../partials/customselect/custom-select-component-commons"
import { useCollectionGroupWizardContext } from "../context/collection-group-wizard-context"
import { useDebouncedCallback } from "use-debounce"

export const CollectionGroupCollectionPointsFilter: React.FC = () => {
  const { t } = useTranslation()
  const { towns, cpFilter, setCpFilter } = useCollectionGroupWizardContext()

  // the changes should be instantly reflected in the UI, but the query should be debounced
  const [localSearch, setLocalSearch] = useState<string | undefined>(cpFilter.search)

  const options = useMemo(() => towns.map((town) => new SelectOption(town.id, town.name)), [towns])

  const setDebouncedCpSearchFilter = useDebouncedCallback((value: string | undefined) => {
    setCpFilter({ ...cpFilter, search: value })
  }, 200)

  return (
    <Stack id="collection-groups-collection-points-filter" direction="row" spacing={2}>
      <Box sx={{ width: "50%" }}>
        <TextField
          size="small"
          label={t("collection_groups.mutate_page.search")}
          type="search"
          variant="outlined"
          value={localSearch ?? ""}
          onChange={(ev) => {
            setLocalSearch(ev.target.value)  
            setDebouncedCpSearchFilter(ev.target.value)
          }}
          fullWidth
        />
      </Box>
      <Box sx={{ width: "50%" }}>
        <CustomAutocomplete
          id="collection-groups-collection-points-filter-towns"
          title={t("collection_points.filter.towns_label")}
          currentlySelectedValues={
            cpFilter.selectedTowns
              .map((name) => options.find((option) => option.name === name))
              .filter((t) => !!t) as SelectOption[]
          }
          availableSelectOptions={options}
          setCurrentlySelectedValues={(selectedOptions) =>
            setCpFilter({ ...cpFilter, selectedTowns: selectedOptions.map((option) => option.name) })
          }
        />
      </Box>
    </Stack>
  )
}
