import React, { useMemo } from "react"
import { CollectionGroupWizard } from "./partials/collection-group-wizard"
import {
  CollectionGroupWizardContextProvider,
  ModeOption,
  WizardFormValues,
} from "./context/collection-group-wizard-context"
import { useNavigate, useParams } from "react-router-dom"
import { useQuery } from "@apollo/client"
import {
  CollectionGroupWithIDResult,
  CollectionGroupWithIDVariables,
  GETCOLLECTIONGROUPWITHID_QUERY,
} from "../../../api/graphql/queries/get-collection-group-with-id"
import { CircularProgress, Grid } from "@mui/material"
import { toast } from "react-toastify"
import { useTranslation } from "react-i18next"
import { PATH } from "../../../router/router"

export const CollectionGroupEditPage: React.FC = () => {
  const params = useParams<{ collectionGroupId: string }>()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const collectionGroupId = useMemo(() => Number(params.collectionGroupId), [params.collectionGroupId])

  const { data, loading, error } = useQuery<CollectionGroupWithIDResult, CollectionGroupWithIDVariables>(
    GETCOLLECTIONGROUPWITHID_QUERY,
    {
      variables: {
        getCollectionGroupWithIdId: collectionGroupId,
      },
      onError: () => {
        toast.error(t("generic_error"))
        navigate(PATH.COLLECTION_GROUP_OVERVIEW.route)
      },
    },
  )

  const groupData = data?.getCollectionGroupWithID
  const materialIds = groupData?.materials?.map((mat) => Number(mat.id)) || []
  const collectionPointIds = groupData?.collection_points?.map((pnt) => Number(pnt.id)) || []

  const groupFormData: WizardFormValues = {
    districtId: Number(groupData?.district_id),
    label: groupData?.label,
    materialIds: materialIds,
    collectionPointIds: collectionPointIds,
    selectedTown: undefined,
  }

  return (
    <>
      {loading && (
        <Grid container justifyContent="center" alignItems="center">
          <CircularProgress />
        </Grid>
      )}
      {!loading && !error && (
        <CollectionGroupWizardContextProvider initialData={groupFormData} mode={ModeOption.Edit}>
          <CollectionGroupWizard groupId={Number(groupData?.id)} />
        </CollectionGroupWizardContextProvider>
      )}
    </>
  )
}
