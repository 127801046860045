import { gql } from "@apollo/client"
import {
  getTourStatsWithId,
  getTourStatsWithIdVariables,
  getTourStatsWithId_getTourStatsWithId_emptying_stats_collected_amount_stats,
  getTourStatsWithId_getTourStatsWithId_stop_infos,
  getTourStatsWithId_getTourStatsWithId_stop_infos_weighings,
  getTourStatsWithId_getTourStatsWithId_stop_infos_stop_filllevels,
  getTourStatsWithId_getTourStatsWithId_stop_infos_stop_filllevels_material,
} from "./types/getTourStatsWithId"

export const GETTOURSTATS_WITH_ID_QUERY = gql`
  fragment stopInfo on StopInfo {
    stop_id
    id
    type
    name
    fixed_unload_interval
    reason
    lng
    lat
    sequence_number
    street
    town
    postal
    place
    updated_at
    leave_at
    vehicle_utilizations {
      level
      material {
        id
        name
      }
    }
    weighings {
      id
      weighing_note_number
      weighing_note_image
      gross
      tare
      net
      inserted_at
    }
    collection_point_images {
      id
      inserted_at
      url
      type
      subtype
      alternative_subtype
      image_type {
        id
        name
      }
      sub_image_type {
        id
        name
      }
      tour_id
    }
    stop_filllevels {
      filllevel
      amount
      material {
        id
        name
      }
    }
    status
  }

  query getTourStatsWithId($id: Int!, $regionId: ID!, $regionType: RegionType!) {
    getTourStatsWithId(id: $id, regionId: $regionId, regionType: $regionType) {
      tour {
        id
        tour_parameters_id
        countOfDefectContainers
        duration
        actual_duration
        distance_driven
        district_id
        date
        updated_at
        deletable
        manuallyCreated
        finished
      }
      emptying_stats {
        partner
        tour_id
        licence_plate
        tour_id
        collected_amount_stats {
          material_id
          material_name
          amount
          amountDisposed
        }
      }
      stop_infos {
        ...stopInfo
      }
      stopsInsertedByDriver {
        ...stopInfo
      }
    }
  }
`

export type TourStatsResult = getTourStatsWithId
export type GetTourStatsWithIdVariables = getTourStatsWithIdVariables
export type CollectedAmountStats = getTourStatsWithId_getTourStatsWithId_emptying_stats_collected_amount_stats
export type StopInfo = getTourStatsWithId_getTourStatsWithId_stop_infos
export type StopInfoWeighing = getTourStatsWithId_getTourStatsWithId_stop_infos_weighings
export type StopFilllevel = getTourStatsWithId_getTourStatsWithId_stop_infos_stop_filllevels
export type StopFilllevelMaterial = getTourStatsWithId_getTourStatsWithId_stop_infos_stop_filllevels_material
