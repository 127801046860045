import { gql } from "@apollo/client"
import {
  concurrentTourParameters,
  concurrentTourParametersVariables,
  concurrentTourParameters_concurrentTourParameters,
} from "./types/concurrentTourParameters"

export const CONCURRENT_TOUR_PARAMETERS_QUERY = gql`
  query concurrentTourParameters(
    $districtId: ID!
    $townIds: [ID!]!
    $materialIds: [ID!]!
    $startDate: DateTime!
    $days: [Day!]!
    $collectionGroupId: ID
  ) {
    concurrentTourParameters(
      districtId: $districtId
      townIds: $townIds
      materialIds: $materialIds
      startDate: $startDate
      days: $days
      collectionGroupId: $collectionGroupId
    ) {
      id
    }
  }
`

export type ConcurrentTourParametersResult = concurrentTourParameters
export type ConcurrentTourParametersVariables = concurrentTourParametersVariables
export type ConcurrentTourParameters = concurrentTourParameters_concurrentTourParameters
