import React, { useCallback, useMemo, useState } from "react"
import { useCollectionGroupWizardContext, WizardFormValues } from "../context/collection-group-wizard-context"
import { OutlinedSelect } from "../../../partials/layout/selection/outlined-select"
import { SelectPair } from "../../../partials/layout/selection/select-pair"
import { ConfirmDialog } from "../../../partials/layout/dialog/confirm-dialog"
import { useTranslation } from "react-i18next"

const emptyFormValues: WizardFormValues = {
  districtId: undefined,
  label: undefined,
  materialIds: undefined,
  collectionPointIds: undefined,
  selectedTown: undefined,
}

export const DistrictInput: React.FC = () => {
  const { t } = useTranslation()
  const { setFormValues, formValues, districts, mode } = useCollectionGroupWizardContext()

  const options = useMemo(() => districts.map((district) => new SelectPair(district.id, district.name)), [districts])
  const [newDistrictId, setNewDistrictId] = useState<string | undefined>(undefined)
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)

  const handleValueChange = useCallback(
    (newValue: string) => {
      if (mode === "edit") {
        setNewDistrictId(newValue)
        setConfirmDialogOpen(true)
      } else {
        setFormValues({ ...formValues, districtId: Number(newValue) })
      }
    },
    [mode, setFormValues, formValues],
  )

  const onClose = useCallback(() => {
    setConfirmDialogOpen(false)
    setNewDistrictId(undefined)
  }, [])

  const onConfirm = useCallback(() => {
    setFormValues({ ...emptyFormValues, districtId: Number(newDistrictId) })
    setConfirmDialogOpen(false)
    setNewDistrictId(undefined)
  }, [newDistrictId, setFormValues])

  return (
    <>
      <ConfirmDialog
        open={confirmDialogOpen}
        onClose={onClose}
        onConfirm={onConfirm}
        confirmText={t("yes")}
        heading=""
        text={t("collection_groups.mutate_page.confirm_change_district")}
        negativeText={t("no")}
      />
      <OutlinedSelect
        options={options}
        onValueChange={(newValue) => handleValueChange(newValue)}
        value={formValues?.districtId?.toString() ?? ""}
      />
    </>
  )
}
