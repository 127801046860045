import { Box, Divider, Stack, TextField } from "@mui/material"
import React from "react"
import { useTranslation } from "react-i18next"
import { CollectionGroupWizardGeneralSection } from "./collection-group-wizard-general-section"
import { useCollectionGroupWizardContext } from "../context/collection-group-wizard-context"
import { MaterialInput } from "./material-input"
import { DistrictInput } from "./district-input"

interface Props {}

export const CollectionGroupGeneralForm: React.FC<Props> = (props) => {
  const { t } = useTranslation()
  const { formValues, setFormValues, materials, mode, collectionPointsForMap: allCollectionPoints } = useCollectionGroupWizardContext()

  const setMaterialsAndFilterCps = (ids: number[]) => {
    // when a material is selected / deselected, we need to filter the collection point ids to only contain collection points that include the selected materials
    const currentlySelectedCollectionPointIds = formValues?.collectionPointIds ?? []
    const collectionPoints = allCollectionPoints.filter((cp) => currentlySelectedCollectionPointIds.includes(Number(cp.id)))

    const collectionPointsForMaterials = collectionPoints.filter((cp) => {
      const collectionPointMaterialIds = cp.containers.flatMap((c) => c.material_id)
      return ids.some((id) => collectionPointMaterialIds.includes(id))
    })

    setFormValues({
      ...formValues,
      materialIds: ids,
      collectionPointIds: collectionPointsForMaterials.map((cp) => Number(cp.id)),
    })
  }

  return (
    <Stack direction="column" spacing={2}>
      <CollectionGroupWizardGeneralSection
        title={t("collection_groups.mutate_page.collection_district")}
        description={t("collection_groups.mutate_page.collection_district_desc")}
        input={
          <Box sx={{ width: "70%", pl: 4 }}>
            <DistrictInput />
          </Box>
        }
      />

      <Divider />

      <CollectionGroupWizardGeneralSection
        title={t("collection_groups.mutate_page.group_label")}
        description={t("collection_groups.mutate_page.group_label_desc")}
        input={
          <Box sx={{ width: "70%", pl: 4 }}>
            <TextField
              fullWidth
              variant="outlined"
              value={formValues?.label ?? ""}
              onChange={(ev) => setFormValues({ ...formValues, label: ev.target.value })}
              inputProps={{ maxLength: 40 }}
            />
          </Box>
        }
      />

      <Divider />

      <CollectionGroupWizardGeneralSection
        title={t("collection_groups.mutate_page.materials")}
        description={t("collection_groups.mutate_page.materials_desc")}
        input={
          <Box sx={{ width: "70%" }}>
            <MaterialInput
              materials={materials}
              selectedMaterialIds={formValues?.materialIds ?? []}
              setSelectedMaterialIds={(ids) => setMaterialsAndFilterCps(ids)}
              mode={mode}
            />
          </Box>
        }
      />
    </Stack>
  )
}
